import React, {useContext, useState} from "react";
import '../../css/schedule.css'
import ActivityInformationModal from "./ActivityInformationModal";
import { UserContext } from "../../Services/UserService/userContext";
import {Dialog, Typography} from "@mui/material";
import useFetch from "../../Hooks/useFetch";
import {formatDateAndTime, minutesDiff} from "../../utilities";
import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext";

import {
    Box, Card
} from "@mui/material";
function ActivityBlock(props) {
    const {
        initialActivity,
        blockSize,
        event
    } = props
    const {user} = useContext(UserContext);
    const {currentSchedule} = useContext(ScheduleContext)

    const [activity, setActivity] = useState(initialActivity);

    const {data: student, loading: studentLoading} = useFetch(`/students/${initialActivity.student_id}`)
    const {data: employer, loading: employerLoading} = useFetch(`/employers/${initialActivity.employer_id}`)
    const {data: activityTypes, loading: activityTypesLoading} = useFetch(`/activity-types`)

    const [modalOpen, setModalOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('Checked-in to Interview!')
    const [errorMessage, setErrorMessage] = useState('Failed to check-in. Try again.')

    let startTime = formatDateAndTime(activity).split(", ")[1].split(":")
    //let startTimeOld = activity.start_time.split('T')[1].split(":")
    let activityDuration = currentSchedule.activity_duration
    let height = (activityDuration/60)*blockSize

    // get currentSchedule offset time + activity start time and make the height be equal to blocksize / duration in relation to hours
    // topOffset = timeDiff of current schedule & activity start
    let timeDiff = minutesDiff(new Date(event.data.start_time), new Date(activity.start_time))
    let topOffset = timeDiff/60 *  blockSize;

    if(studentLoading || employerLoading || activityTypesLoading) return <Dialog open={modalOpen}><h1>Loading</h1></Dialog>

    let activityType = activityTypes.data.filter((type) => type.id === activity.activity_type)[0].activity_type

    let backgroundColor = activity.checked_in ? "#76ff03" : "#e0e0e0"

    const fontSize = 12

    let blockTitle = ""
    let blockDescription = activity.description

    if (student.data) {
        blockTitle = student.data.first_name +" " + student.data.last_name +'\'s Interview'
    } else {
        blockTitle = activityType
    }

    return(
        <>
            { student.data ?
                <>
                    <Box sx={{
                        width: "100%",
                        height: height+"px",
                        display: "flex",
                        position: 'absolute',
                        fontSize: "12px",
                        top: topOffset,
                    }}
                         key={activity.id}
                    >
                        <Box sx={{width: "65px"}}/>
                        <Card
                            sx={{
                                flexGrow: 1,
                                cursor: "pointer",
                                backgroundColor: backgroundColor
                            }}
                            onClick={() => setModalOpen(true)}
                            >
{/*
                        >{activity.description ?? `Interview with ${user.permissionLevel === 1 ? employer.data.employer_name : user.permissionLevel === 2 ? `${student.data.first_name} ${student.data.last_name}` : `${student.data.first_name} ${student.data.last_name} and ${employer.data.employer_name}`}`}
*/}
                            <Typography sx ={{fontWeight: 'bold'}} fontSize={fontSize+1}>{blockTitle}</Typography>
                            <Typography fontSize={fontSize}>{blockDescription}</Typography>
                        </Card>
                    </Box>
                    <ActivityInformationModal 
                        activity={activity}
                        setActivity={setActivity} 
                        activityDuration={activityDuration} 
                        modalOpen={modalOpen} 
                        setModalOpen={setModalOpen} 
                        user={user} 
                        employer={employer} 
                        student={student} 
                        intervieweeName={user.permissionLevel === 1 ? employer.data.employer_name : user.permissionLevel === 2 ? `${student.data.first_name} ${student.data.last_name}` : `${student.data.first_name} ${student.data.last_name} and ${employer.data.employer_name}`} 
                        setShowAlert={setShowAlert} 
                        setIsSuccess={setIsSuccess} 
                        setSuccessMessage={setSuccessMessage}
                        setErrorMessage={setErrorMessage}
                        activityType={activityType}
                    />
{/*                    <CommonAlert
                        isSuccess={isSuccess}
                        showAlert={showAlert}
                        setShowAlert={setShowAlert}
                        successMessage={successMessage}
                        errorMessage={errorMessage}
                    />*/}
                </>
                :
                <>
                    <Box sx={{
                        width: "100%",
                        height: height+"px",
                        display: "flex",
                        position: 'absolute',
                        fontSize: "12px",
                        top: topOffset,

                    }}
                         key={activity.id}
                    >
                        <Box sx={{width: "65px"}}/>
                        <Card
                            sx={{
                                flexGrow: 1,
                                cursor: "pointer",
                                backgroundColor: "#424242",
                                color: "#fafafa"
                            }}
                            onClick={() => setModalOpen(true)}
                        >
                            <Typography sx ={{fontWeight: 'bold'}} fontSize={fontSize + 1}>{blockTitle}</Typography>
                            <Typography fontSize={fontSize}>{blockDescription}</Typography>
                        </Card>
                    </Box>
                    <ActivityInformationModal 
                        activity={activity} 
                        setActivity={setActivity}
                        activityDuration={activityDuration} 
                        modalOpen={modalOpen} 
                        setModalOpen={setModalOpen} 
                        user={user} 
                        employer={employer} 
                        student={student} 
                        setShowAlert={setShowAlert} 
                        setIsSuccess={setIsSuccess} 
                        setSuccessMessage={setSuccessMessage}
                        setErrorMessage={setErrorMessage}
                        activityType={activityType}
                    />
{/*                    <CommonAlert
                        isSuccess={isSuccess}
                        showAlert={showAlert}
                        setShowAlert={setShowAlert}
                        successMessage={successMessage}
                        errorMessage={errorMessage}
                    />*/}
                </>
            }
        </>

    )
}

export default ActivityBlock