
import React, { useReducer, useEffect, useContext, useState } from "react";
import {UserContext} from "../UserService/userContext";
import {useNavigate} from "react-router-dom";
import {apiDelete, apiPost, apiPut, getScheduleUserId} from "../../utilities";

import {getCurrentUrlParam} from "../../utilities";
import useFetch from "../../Hooks/useFetch";
import socket from "../../Socket/socket";

export const ScheduleContext = React.createContext(null);


export function ScheduleProvider(props) {
    const {user} = useContext(UserContext)

    const [schedules, setSchedules] = useState([])

    const {data: scheds, loading: schedulesLoading} = useFetch(`/schedules/${getCurrentUrlParam("event")}/${getScheduleUserId(user.internalId)}`);

    let [currentSchedule, setCurrentSchedule] = useState(null);
    let navigate = useNavigate();

    useEffect(()=> {
        if (scheds != null) {
            setSchedules(scheds.data)
            setCurrentSchedule(scheds.data[0])
        }
    }, [scheds])

    function scheduleClicked(schedule) {
        if(schedule == null) {
            setCurrentSchedule(null)
        } else {
            const event = getCurrentUrlParam('event')
            const scheduleOwnerId = getCurrentUrlParam('scheduleOwnerId')
            const scheduleType = getCurrentUrlParam('scheduleType')
            if(scheduleOwnerId && scheduleType) {
                navigate(`/schedule?event=${event}&schedule=${schedule.id}&scheduleOwnerId=${scheduleOwnerId}&scheduleType=${scheduleType}`)
            } else {
                navigate('/schedule?event='+event+'&schedule='+schedule.id)
            }
            setCurrentSchedule(schedule)
            console.log(schedule)
        }
    }
    function getCurrentSchedule() {
        const id = getCurrentUrlParam('schedule')
        return schedules.find(s => s.id == id)
    }
    // Updates the schedule given inside of the schedules array.
    function updateScheduleName(id, name) {
        let newSchedule = null;
        const newSchedules = schedules.map((s) => {
            if(s.id === id) {
                newSchedule = s
                newSchedule.schedule_name = name
                return newSchedule
            } else {
                return s
            }
        })
        setSchedules(newSchedules)
        return newSchedule
    }

    function addSchedule(schedule) {
        schedule["activities"] = []
        setSchedules([...schedules, schedule])
        setCurrentSchedule(schedule)
    }

    function checkIn(activityId) {
        let allSchedules = [...schedules]
        const currSchedule = schedules.find(
            a => a.id === currentSchedule.id
        );
        const newActivities = currSchedule.activities.map((s) => {
            if(s.id === activityId) {
                let newActivity = s
                newActivity.checked_in = true
                return newActivity
            } else {
                return s
            }
        })
        currSchedule["activities"] = [newActivities]
        setSchedules(allSchedules)
    }

    function updateActivity(activity) {
        let allSchedules = [...schedules]
        const currSchedule = schedules.find(
            a => a.id === currentSchedule.id
        );
        const newActivities = currSchedule.activities.map((s) => {
            if(s.id === activity.id) {
                return activity
            } else {
                return s
            }
        })

        currSchedule["activities"] = [newActivities]
        setSchedules(allSchedules)
    }

    function newSchedules(schedules) {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);
        const id = params.get('schedule')
        if (schedules != null) currentSchedule = schedules.find(s => s.id == id)

        setSchedules(schedules)
    }
    function deleteSchedule(id) {
        apiDelete(`/schedules/${id}`)
            .then(r => {
                setSchedules(schedules.filter(s => s.id !== id))
                    if (schedules.length > 0) {
                        setCurrentSchedule(schedules[0])
                    } else {
                        setCurrentSchedule(null)
                    }
            }
            )
    }
    function deleteActivity(activityId) {
        let allSchedules = [...schedules]
        const newSchedules = allSchedules.filter(function(schedule) {
            if (schedule.id == currentSchedule.id) {
                schedule.activities = currentSchedule["activities"].filter(a =>
                    a.id !== activityId
                )
            }
            return schedule

        })
        setSchedules(newSchedules)
    }

    async function editScheduleName(name) {
        let body = {
            "schedule_name": name
        }
        let response = await apiPut(`/schedules/${currentSchedule.id}`, body)
        let sched = await updateScheduleName(response.data.id, response.data.schedule_name)
        setCurrentSchedule(sched)
        return sched
    }
    function addActivity(activity) {
        let allSchedules = [...schedules]
        const newSchedules = schedules.find(
            a => a.id === currentSchedule.id
        );
        newSchedules["activities"] = [...newSchedules["activities"], activity]
        setSchedules(allSchedules)
    }
    function removeActivity(activityId) {
        let allSchedules = [...schedules]
        const newSchedules = schedules.find(
            a => a.id === currentSchedule.id
        );

        let activities = newSchedules["activities"].filter(a =>
            a.id !== activityId
        )
        newSchedules["activities"] = [activities]
        setSchedules(allSchedules)
    }

    const contextValues = {
        newSchedules,
        setSchedules,
        currentSchedule,
        deleteSchedule,
        schedulesLoading,
        addSchedule,
        getCurrentSchedule,
        deleteActivity,
        setCurrentSchedule,
        addActivity,
        removeActivity,
        checkIn,
        editScheduleName,
        scheduleClicked,
        schedules
    }
    // UserContexts allows us to just create 1 instance that's accessible everywhere in the react app
    return (
        <ScheduleContext.Provider value={contextValues}>
            {props.children}
        </ScheduleContext.Provider>
    )
}
// Function that is called in other components. Basically exports all functions within contextValues
export function useSchedule() {
    const context = useContext(ScheduleContext);
    if (!context) {
        throw new Error(
            "useSchedule must be used within a ScheduleProvider. Wrap a parent component in <ScheduleProvider> to fix error"
        )
    }
    return context
}