import React from 'react'
import { Button, Modal, Box } from '@mui/material'

export default function DeleteConfirmationModal(props) {
    const {setModalOpen, modalOpen, currentSchedule, handleDeleteClick, sublocation=false} = props

    return(
        <>
            <Modal open={modalOpen} sx={{top: "25%", left: "40%", height: "10%"}}>
                <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: 'white', width: "40%", minHeight: "10%"}}>
                    <h3>{`Are you sure you want to delete this?`}</h3>
                    <div style={{display: 'flex', width: "45%", marginTop: 'auto', justifyContent: "space-between", paddingBottom: "20px"}}>
                        <Button
                            sx={{color: "black", backgroundColor: "#5DFF4F", border: "1px solid black", marginTop: 'auto', marginBottom: '10px'}}
                            onClick={() => handleDeleteClick(currentSchedule.id)}
                        >
                            Yes
                        </Button>
                        <Button
                            sx={{color: "black", backgroundColor: "#ef5350", border: "1px solid black", marginTop: 'auto', marginBottom: '10px'}}
                            onClick={()=>setModalOpen(false)}
                        >
                            No
                        </Button>
                    </div>
                    
                </Box>
            </Modal>
        </>
    )
}