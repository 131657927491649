 import React, { useReducer, useEffect, useContext } from "react";
    import userReducer from "./userReducer";
    
    export const UserContext = React.createContext(null);
    
    let initialUser;
    try {
        let userId =  JSON.parse(localStorage.getItem("userId"))
        let internalId = JSON.parse(localStorage.getItem("internalId"))
        let accessToken = JSON.parse(localStorage.getItem("accessToken"))
        let userType = JSON.parse(localStorage.getItem("userType"))
    
        initialUser = {
            id: userId,
            internalId: internalId,
            userType: userType,
            accessToken: accessToken,
        }
    } catch {
        console.error("User could not initialize")
        initialUser = {
            id: null,
            internalId: null,
            userType: "",
            accessToken: null,
        }
    }

    export function UserProvider(props) {
        const [user, userDispatch] = useReducer(userReducer, initialUser)
        useEffect(() =>  {
            if (user.id != null) {
                localStorage.setItem("userId", JSON.stringify(user.id))
                localStorage.setItem("internalId", JSON.stringify(user.internalId))
                localStorage.setItem("accessToken", JSON.stringify(user.accessToken))
                localStorage.setItem("userType", JSON.stringify(user.userType))
            }
        })

        // We can create functions that can be exported for checking, such as isAuth that will most likely be used elsewhere.
        function isAuth(authLevels) {
            if (authLevels.default) return true;
            if (user == null || user.userType == null) return false
            return authLevels[user.userType]
        }
        const contextValues = {
            user,
            userDispatch,
            isAuth
        }
        // UserContexts allows us to just create 1 instance that's accessible everywhere in the react app
        return (
            <UserContext.Provider value={contextValues}>
                {props.children}
            </UserContext.Provider>
        )
    }
    // Function that is called in other components. Basically exports all functions within contextValues
    export function useUser() {
        const context = useContext(UserContext);
        if (!context) {
            throw new Error(
                "useUser must be used within a UserProvider. Wrap a parent component in <UserProvider> to fix error"
            )
        }
        return context
    }