import React from 'react'
import { Alert } from '@mui/material'

function ActivityAlert(props) {
    const {
        isSuccess,
        showAlert,
        setShowAlert
    } = props

    return(
        <Alert severity={isSuccess ? "success" : "error"} onClose={() => {setShowAlert(false)}} sx={{position: 'fixed', left: '40%', bottom: '5%', visibility: showAlert ? 'visible' : 'hidden'}}>
            {isSuccess ? 'Successfully Created New Activity!' : 'Error Creating Activity. Please make sure to fill out both fields and try again.'}
        </Alert>
    )
}

export default ActivityAlert