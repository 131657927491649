import React, { useContext } from "react"
import { UserContext } from "../../Services/UserService/userContext";
import useFetch from "../../Hooks/useFetch";
import '../../css/base.css'
import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext";
import ActivityBlock from "../Activities/ActivityBlock";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { ScheduleBackground, HourMarkers } from "./ScheduleBackgroundUtility";
import ScheduleHeader from "./ScheduleHeader";
import ScheduleFooter from "./ScheduleFooter";
import {convertDayDiffToHours} from "../../utilities";
import HourBlock from "../Activities/HourBlock";
import ActivityBlockStudent from "../Activities/ActivityBlockStudent";

function StudentSchedule() {
    const {user} = useContext(UserContext)
    let navigate = useNavigate();

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const careerFairId = params.get('event')
    const scheduleOwnerId = params.get('scheduleOwnerId')

    let userType = 'STUDENT'

    const {data: activities, loading: activitiesLoading} = useFetch(`/activities/${careerFairId}/${scheduleOwnerId ? scheduleOwnerId : user.internalId}/?type=${userType}`);
    const {data: eventData, loading: careerFairLoading} = useFetch("/events/"+careerFairId)
    const {data: student, loading: studentLoading} = useFetch(`/students/${scheduleOwnerId ? scheduleOwnerId : user.internalId}`)
    const {data: activityTypes, loading: activityTypesLoading} = useFetch(`/activity-types`)

    if(careerFairLoading || activitiesLoading || studentLoading || activityTypesLoading) return <h1>Loading</h1>


    const startTime = new Date(eventData.data.start_time)
    const endTime = new Date(eventData.data.end_time)
    const totalHours = convertDayDiffToHours(startTime, endTime);
    const blockSize = 75

    function renderBlock(data) {
        return <HourBlock startTime={data.startTime} blockSize ={blockSize}/>
    }
    function getBlocks() {
        var blocks = [];
        for (var i = -1; i < totalHours-1; i++) {
            blocks.push(
                {
                    startTime: startTime.setHours(startTime.getHours()+1),
                    blockSize: blockSize
                }
            );
        }
        return blocks;
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{flexFlow: 'column', paddingTop: '8px', flex: "0 0 auto"
            }}>
                <Grid container margin={0}
                      sx={{            justifyContent:"center",
                          alignItems: "center",
                          display: 'flex'}}
                >
                    <Typography variant="h5" gutterBottom textAlign="center">{student.data.first_name} {student.data.last_name}'s Interviews</Typography>
                </Grid>

            </Box>
            <Container sx={{flexGrow: 1, flexFlow: 'column', height: '100%'}}>
                <Box sx={{height: '100%', width: '100%', position: "relative"}}>

                    <Box sx={{zIndex: 3, position: 'absolute', width: '100%', height: '100%'}}>
                        {activities.data.map(
                            (activity) => <ActivityBlockStudent
                                initialActivity={activity}
                                blockSize={blockSize}
                                event={eventData}
                                student={student}
                                studentLoading={studentLoading}
                                activityTypes={activityTypes}
                                activityTypesLoading={activityTypesLoading}

                            />)}
                    </Box>

                    <Box sx={{flex: "1 0 auto", overflowy: "auto", height: '100%', zIndex: 2}}>
                        <Box
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"

                            spacing={0}
                        >
                            {getBlocks().map((data) =>  renderBlock(data))}
                        </Box>
                    </Box>
                </Box>

            </Container>
        </Box>
    )
}
export default StudentSchedule