import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import LocationAssignmentComponent from './LocationAssignmentComponent';

function LocationPanel(props) {
    const { index, activeTab, locations, selectedLocation, handleLocationChange } = props;
  
    return (
      <div
        role='tabpanel'
        className={`simple-tab-panel`}
        hidden={activeTab !== index}
        aria-labelledby={`simple-tab-${index}`}
    >
        {activeTab === index && (
            <>
                <div className='location-selection-toolbar'>
                    <h2 style={{paddingLeft: '10px', paddingTop: '5px'}}>Pick Location:</h2>
                    <div style={{display: 'flex', width: "30%", padding: "10px"}}>
                        <FormControl sx={{paddingLeft: '10px', width: "50%"}}>
                            <InputLabel id="locations-select-label">Location</InputLabel>
                            <Select
                            labelId="locations-select-label"
                            id="locations-select"
                            value={selectedLocation}
                            label="Parent Location"
                            onChange={handleLocationChange}
                            >
                                {locations.map((location) => {return(
                                    <MenuItem value={location}>{location.location_name}</MenuItem>
                                )})}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {selectedLocation &&
                    <LocationAssignmentComponent location={selectedLocation} />
                }
            </>
        )}
      </div>
    );
}

export default LocationPanel;