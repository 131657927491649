import React, { useState, useEffect, useContext } from "react";
import CommonAlert from "../../Components/Common/CommonAlert";

export const AlertContext = React.createContext(null);



export function AlertProvider(props) {
    const [activeAlerts, setActiveAlerts] = useState([])


    function addAlert() {

    }
    function renderAlert(alert) {
        return <CommonAlert />
    }

    const commands = {
        addAlert
    }


    async function apiCall(url, apiType, bodyData=null) {
        let baseUrl = process.env.REACT_APP_API
        let options = {
            method: apiType,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                "x-access-token": JSON.parse(localStorage.getItem("accessToken"))
            }
        }
        if (bodyData == null) {
            options.body = JSON.stringify(bodyData)
        }
        try {
            const response = await fetch(baseUrl + url, options);
            if (response.ok) {
                const json = await response.json();
                return json
            } else {
                throw response;
            }
        } catch (e) {

        } finally {

        }
    }



    return (
        <AlertContext.Provider value={commands}>
            {activeAlerts.map((alert) => renderAlert(alert) )}
            {props.children}
        </AlertContext.Provider>
    )
}

export function useAlert() {
    const context = useContext(AlertContext);
    if (!context) {
        throw new Error(
            "useAlert must be used within a AlertProvider. Wrap a parent component in <AlertProvider> to fix error"
        )
    }
    return context
}