import React, { useContext, useState, useEffect } from "react"
import {
    Box,
    Container, Stack
} from "@mui/material";

import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext";
import useFetch from "../../Hooks/useFetch";
import {convertDayDiffToHours, getCurrentUrlParam} from "../../utilities";
import { UserContext } from "../../Services/UserService/userContext";
import ScheduleHeader from "./ScheduleHeader";
import ScheduleFooter from "./ScheduleFooter";
import HourBlock from "../Activities/HourBlock";
import ActivityBlock from "../Activities/ActivityBlock";
import socket from "../../Socket/socket";

function ScheduleBox(props) {
    const {windowWidth} = props
    const {user} = useContext(UserContext)
    const {schedules, checkIn, currentSchedule, schedulesLoading, setSchedules} = useContext(ScheduleContext)

    // Join and leave rooms on component mount and unmount
    useEffect(() => {
        if(currentSchedule) {
            socket.emit('join_rooms', currentSchedule.activities.map(activity => activity.id));
        }
        return () => {
            if(currentSchedule) {
                socket.emit('leave_rooms', currentSchedule.activities.map(activity => activity.id));
            }
        };
    }, [currentSchedule]);


    useEffect(() => {
        function handleActivityUpdate(activityId) {
            checkIn(activityId);
        }
        socket.on('arrived', handleActivityUpdate);
    }, [socket])

    const eventId = getCurrentUrlParam("event")
    const {data: eventData, loading: careerFairLoading} = useFetch("/events/"+eventId)

    if (schedulesLoading || careerFairLoading) return <h1>Loading</h1>

    const startTime = new Date(eventData.data.start_time)
    const endTime = new Date(eventData.data.end_time)
    const totalHours = convertDayDiffToHours(startTime, endTime);

    const blockSize = 75

    function getBlocks() {
        var blocks = [];
        for (var i = -1; i < totalHours-1; i++) {
            blocks.push(
                {
                    startTime: startTime.setHours(startTime.getHours()+1),
                    blockSize: blockSize
                }
            );
        }
        return blocks;
    }
    function renderBlock(data) {
        return <HourBlock startTime={data.startTime} blockSize ={blockSize}/>
    }
    
    if (schedules.length == 0) {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
                <ScheduleHeader eventData={eventData} windowWidth={windowWidth} eventId={eventId}/>
            </Box>
        )
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
            <ScheduleHeader eventData={eventData} windowWidth={windowWidth} eventId={eventId}/>
            <Container sx={{flexGrow: 1, flexFlow: 'column', height: '100%'}}>
                <Box sx={{height: '100%', width: '100%', position: "relative"}}>

                    <Box sx={{zIndex: 3, position: 'absolute', width: '100%', height: '100%'}}>
                        {currentSchedule != null ? currentSchedule.activities.map(
                            (activity) => <ActivityBlock key={activity.id} initialActivity={activity} blockSize={blockSize} event={eventData}/>) : <></>}
                    </Box>

                    <Box sx={{flex: "1 0 auto", overflowy: "auto", height: '100%', zIndex: 2}}>
                        <Box
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"

                            spacing={0}
                        >
                        {getBlocks().map((data) =>  renderBlock(data))}
                        </Box>
                    </Box>
                </Box>

            </Container>

            {user.userType != "STUDENT" ? <ScheduleFooter eventData={eventData} windowWidth={windowWidth} eventId={eventId}/> : <></>}

        </Box>

    )
}

export default ScheduleBox;