import React, { useContext, useState, useEffect } from 'react';
import {Container, OutlinedInput} from "@mui/material";
import CareerFairTable from "../Components/CareerFairTable/CareerFairTable";
import { UserContext } from '../Services/UserService/userContext';
import EditCareerFairModal from '../Components/CareerFairTable/EditCareerFairModal';
import CareerEditAlert from '../Components/CareerFairTable/CareerEditAlert';
import useFetch from '../Hooks/useFetch';
import { apiGet } from '../utilities';
import PageLayout from "../Components/Layout/PageLayout";
import ScheduleBox from "../Components/Schedule/ScheduleBox";


const CareerFairListingPage = () => {
    const {user} = useContext(UserContext)
    const [modalOpen, setModalOpen] = useState(true)
    const [chosenEvent, setChosenEvent] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [events, setEvents] = useState(null)
    const [eventWasUpdated, setEventWasUpdated] = useState(false)

    const {data, loading, error} = useFetch("/events");

    const getEventsAfterUpdate = async () => {
        let response = await apiGet("/events")
        setEvents(response.data)
    }

    useEffect(() => {
        if(eventWasUpdated) {
            getEventsAfterUpdate()
            setEventWasUpdated(false)
        }
    }, [eventWasUpdated])

    useEffect(() => {
        if (chosenEvent != null) {
            setModalOpen(true)
        }
    }, [chosenEvent])

    useEffect(() => {
        if (modalOpen == false) {
            setChosenEvent(null)
        }
    }, [modalOpen])

    if(loading) return <h1>Loading</h1>
    if(error) return <h1>Error</h1>
    
    return(
        <PageLayout
            sidebarElements={null}
        >
                <CareerFairTable events={events ? events : data["data"]} user={user} setChosenEvent={setChosenEvent} />
                {chosenEvent ?
                <EditCareerFairModal
                    chosenEvent={chosenEvent}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    setShowAlert={setShowAlert}
                    setIsSuccess={setIsSuccess}
                    setEventWasUpdated={setEventWasUpdated}
                /> : <></>
                }

                <CareerEditAlert isSuccess={isSuccess} showAlert={showAlert} setShowAlert={setShowAlert} />
        </PageLayout>

    )
}
export default CareerFairListingPage