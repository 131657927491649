import React, {useContext, useState} from "react";
import {Button} from "@mui/material";
import {UserContext} from "../../Services/UserService/userContext";
import CommonAlert from "../Common/CommonAlert";
import CreateSublocationModal from "./CreateSublocationModal";

function CreateSublocationButton(props) {
    const {careerFairId, styleName, name, locations} = props
    const {user} = useContext(UserContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    return(
        <>
            <Button onClick={()=>setModalOpen(true)}>
                Create Sublocation
            </Button>

            <CreateSublocationModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setShowAlert={setShowAlert}
                setIsSuccess={setIsSuccess}
                careerFairId={careerFairId}
                locations={locations}
            />

<CommonAlert isSuccess={isSuccess} showAlert={showAlert} setShowAlert={setShowAlert} successMessage={'Successfully Created New Sublocation!'} errorMessage={'Error creating new sublocation. Please try again.'} />
        </>

    )
}

export default CreateSublocationButton