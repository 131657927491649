import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../Services/UserService/userContext';
import { loginPost } from '../utilities';
import {Container, TextField, Button, FormControl, InputLabel, NativeSelect} from "@mui/material";
import {useNavigate} from "react-router-dom";
import socket from '../Socket/socket';

const defaultRoute = "/CareerFairs";
const redirectMs = 1000;

function LoginPage() {
    let navigate = useNavigate();

    const [symplicityID, setSymplicityID] = useState("")
    const [userType, setUserType] = useState("STUDENT")
    const [isLoading, setIsLoading] = useState(false)
    const {user, userDispatch} = useContext(UserContext)

    //set windowWidth to the current width of the window
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

    //add a listener to the window object to change windowWidth whenever the window is resized
    React.useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

        let loginInfo = {
        "symplicityId": symplicityID,
        "userType": userType
    }
    const pLevels = {"STUDENT": 1, "EMPLOYER": 2, "ADMIN": 3}
    useEffect(() => {
        if(isLoading === true) {
            if(user.accessToken === null) {
                loginPost('/login', loginInfo)
                .then(data => {
                    if(data) {
                        userDispatch({ type: "signIn", id: symplicityID, internalId: data.internalId, accessToken: data.accessToken, userType: userType})
                        setTimeout(() => {
                            navigate(defaultRoute)
                        }, redirectMs)


                    }
                })
            } else {
                userDispatch({ type: "signOut", accessToken: null })
            }
            setIsLoading(false)
        }
    }, [isLoading])

    function myOnClick() {
        setIsLoading(true)
    }
    
    if (user.accessToken === null) {
        return(
            <Container maxWidth="lg" sx={{display: 'flex', flexDirection: "column", justifyContent: "center", height: '90vh', alignItems: 'center'}}>
                <h1>Login Page</h1>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center', height: "10%", width: windowWidth < 950 ? "100%" : "50%"}}>
                    Symplicity ID: <TextField id="outlined-basic" label="Symplicity ID" variant="outlined" onChange={(action) => setSymplicityID(action.target.value)} />
                    {windowWidth > 950 &&
                        <FormControl>
                            <InputLabel htmlFor="uncontrolled-native">
                                User Type
                            </InputLabel>
                            <NativeSelect
                                defaultValue={userType}
                                inputProps={{
                                name: 'User Type',
                                id: 'uncontrolled-native',
                                }}
                                onChange={(action) => setUserType(action.target.value)}
                            >
                                <option value={'ADMIN'}>Admin</option>
                                <option value={'EMPLOYER'}>Employer</option>
                                <option value={'STUDENT'}>Student</option>
                            </NativeSelect>
                        </FormControl>
                    }
                </div>
                {windowWidth < 950 &&
                    <div style={{display: 'flex', justifyContent: 'space-between', alignSelf: 'flex-start', alignItems: 'center', textAlign: 'center', height: "10%", width: "75%"}}>
                        Pick User Type:
                        <FormControl>
                            <InputLabel htmlFor="uncontrolled-native">
                                User Type
                            </InputLabel>
                            <NativeSelect
                                defaultValue={userType}
                                inputProps={{
                                name: 'User Type',
                                id: 'uncontrolled-native',
                                }}
                                onChange={(action) => setUserType(action.target.value)}
                            >
                                <option value={'ADMIN'}>Admin</option>
                                <option value={'EMPLOYER'}>Employer</option>
                                <option value={'STUDENT'}>Student</option>
                            </NativeSelect>
                        </FormControl>
                    </div>
                }
                <Button variant="contained" sx={{margin: "25px"}} onClick={() => myOnClick()}>Login</Button>
            </Container>
        )
    } else {
        return(
            <Container maxWidth="lg" sx={{display: 'flex', flexDirection: "column", justifyContent: "center", height: '90vh', alignItems: 'center'}}>
                <h1>You have successfully logged in!</h1>
                <Button variant="contained" sx={{margin: "25px"}} onClick={() => {navigate(defaultRoute)}}>Enter</Button>
                <Button variant="contained" sx={{margin: "25px"}} onClick={() => myOnClick()}>Logout</Button>
            </Container>
        )
    }
}
export default LoginPage