/*
This react component will hold all initializations of any sort of global contexts we need
such as a user service for example
 */

import {UserProvider} from "./UserService/userContext";
import {ScheduleProvider} from "./ScheduleService/scheduleContext";
import {SocketProvider} from "../Socket/SocketContext";
import {AlertProvider} from "./AlertService/alertContext";

function ContextProvider(props) {
    return (
        <>
        <SocketProvider>
            <UserProvider>
                <AlertProvider>
                    {props.children}
                </AlertProvider>
            </UserProvider>
        </SocketProvider>
        </>
    )
}
export default ContextProvider