import React, {useContext, useState} from "react";
import {Button} from "@mui/material";
import {UserContext} from "../../Services/UserService/userContext";
import CommonAlert from "../Common/CommonAlert";
import CreateLocationModal from "./CreateLocationModal";

function CreateLocationButton(props) {
    const {careerFairId, styleName, name} = props
    const {user} = useContext(UserContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    return(
        <>
            <Button onClick={()=>setModalOpen(true)}>
                Create Location
            </Button>

            <CreateLocationModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setShowAlert={setShowAlert}
                setIsSuccess={setIsSuccess}
                careerFairId={careerFairId}
                user={user}
            />

            <CommonAlert isSuccess={isSuccess} showAlert={showAlert} setShowAlert={setShowAlert} successMessage={'Successfully Created New Location!'} errorMessage={'Error creating new location. Please try again.'} />
        </>

    )
}

export default CreateLocationButton