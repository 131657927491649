import React from "react"
import "../../css/studentresult.css";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";


function StudentResult(props) {
    const { student } = props;

    return (
        <div>
            {props.selected == student.id ? 
            <div>
            <div class="result-container">
                <div>
                    <h3>{student.first_name} {student.last_name}</h3>
                    {student.phone_number && <h5> Phone: {student.phone_number}</h5>}
                    {(student.emails && student.emails[0]) && <h5> Email: {student.emails[0]}</h5>}
                </div>
                <div class="button-container">
                    <IconButton onClick={()=> props.clear()} ><CloseIcon/></IconButton>
                </div>
            </div>
            </div>
            : 
            <div className={((props.selected == student.id) ? 'selected-student' : '')} onClick={() => props.callback(student)}>
            <div class="result-container">
                <div>
                    <h3>{student.first_name} {student.last_name}</h3>
                    {student.phone_number && <h5> Phone: {student.phone_number}</h5>}
                    {(student.emails && student.emails[0]) && <h5> Email: {student.emails[0]}</h5>}
                </div>
            </div>
            <hr/>
        </div>
             }
        </div>
    )
}
export default StudentResult;