import React from 'react'
import { Alert } from '@mui/material'

function ScheduleCreationAlert(props) {
    const {
        isSuccess,
        showAlert,
        setShowAlert
    } = props

    return(
        <Alert severity={isSuccess ? "success" : "error"} onClose={() => {setShowAlert(false)}} sx={{position: 'fixed', left: '40%', bottom: '5%', visibility: showAlert ? 'visible' : 'hidden'}}>
            {isSuccess ? 'Successfully Created New Schedule!' : 'Error Creating Schedule. Please make sure to fill out both fields and try again.'}
        </Alert>
    )
}

export default ScheduleCreationAlert