import React, {useContext, useState} from "react";
import {ListItemButton, ListItemText} from "@mui/material";
import {UserContext} from "../../Services/UserService/userContext";
import AddActivityModal from "./AddActivityModal";
import ActivityAlert from "./ActivityAlert";
import CommonAlert from "../Common/CommonAlert";

function CreateActivityButton(props) {
    const {careerFair, styleName, name} = props
    const {user} = useContext(UserContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertText, setAlertText] = useState("Successfully Created New Activity")
    const display = "CREATE ACTIVITY"

    return(
        <>
            <ListItemButton onClick={()=>setModalOpen(true)}>
                <ListItemText primary={display} />
            </ListItemButton>


            <AddActivityModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setShowAlert={setShowAlert}
                setIsSuccess={setIsSuccess}
                setAlertText={setAlertText}
                careerFair={careerFair}
                user={user}
            />

            <CommonAlert severity={isSuccess ? "success" : "error"} showAlert={showAlert} setShowAlert={setShowAlert} alertTitle={`Activity Creation ${isSuccess ? 'Success' : 'Error'}`} alertMessage={alertText} />
        </>

    )
}

export default CreateActivityButton