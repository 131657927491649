import React from 'react'
import { Alert } from '@mui/material'

function CareerEditAlert(props) {
    const {
        isSuccess,
        showAlert,
        setShowAlert
    } = props

    return(
        <Alert severity={isSuccess ? "success" : "error"} onClose={() => {setShowAlert(false)}} sx={{position: 'fixed', left: '40%', bottom: '5%', visibility: showAlert ? 'visible' : 'hidden'}}>
            {isSuccess ? 'Successfully Edited Event!' : 'Error Editing Event. Please make sure all fields are filled and there are one or more durations.'}
        </Alert>
    )
}

export default CareerEditAlert