import React, { useContext } from "react"
import { UserContext } from "../Services/UserService/userContext";
import '../css/base.css'

import StudentSchedule from "../Components/Schedule/StudentSchedule";
import PageLayout from "../Components/Layout/PageLayout";
import ScheduleBox from "../Components/Schedule/ScheduleBox";
import {ScheduleProvider} from "../Services/ScheduleService/scheduleContext";
import CreateActivityButton from "../Components/Activities/CreateActivityButton";
import {getCurrentUrlParam} from "../utilities";
function SchedulePage() {
    const {user} = useContext(UserContext)

    let sidebarElements = [
        <CreateActivityButton />
    ]

    if (user.userType == "STUDENT" || (getCurrentUrlParam("scheduleType") == "student")) {
        return (
            <PageLayout
                sidebarElements={null}
            >
                <StudentSchedule />
            </PageLayout>
        )
    }
    return (
        <ScheduleProvider viewType={user.userType}>
            <PageLayout
                sidebarElements={sidebarElements}
            >
                <ScheduleBox/>
            </PageLayout>
        </ScheduleProvider>

    )

}
export default SchedulePage