import React, {useState, useContext} from "react"
import {Button, Modal, Box, TextField, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import { apiPost } from "../../utilities";


function CreateLocationModal(props) {
    const {
        modalOpen, 
        setModalOpen,
        setShowAlert,
        setIsSuccess,
        careerFairId,
        user,
    } = props

    const [newLocationName, setNewLocationName] = useState(null)

    async function createScheduleClick() {
        let newLocationInfo = {
            "location_name": newLocationName,
            }
        let response = await apiPost(`/locations/${careerFairId}`, newLocationInfo)
        response.data ? setIsSuccess(true) : setIsSuccess(false)
        setShowAlert(true)
        setNewLocationName(null)
        setModalOpen(false)
    }

    return(
        <Modal open={modalOpen} sx={{top: "25%", left: "40%"}}>
            <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: 'white', width: "50%", height: "50%", minHeight: "300px"}}>
                <h3>Create Your New Location</h3>
                
                <div style={{display: 'flex', flexDirection: "column", alignItems: "flex-start"}}>
                    <div style={{display: 'flex', padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                        Location Name: <TextField id="outlined-basic" label="Location Name" variant="outlined" onChange={(action) => setNewLocationName(action.target.value)} sx={{paddingLeft: '10px'}} />
                    </div>
                </div>
                <div style={{display: 'flex', width: "45%", marginTop: 'auto', justifyContent: "space-between", paddingBottom: "20px"}}>
                    <Button
                        sx={{color: "black", backgroundColor: "#ECECEC", border: "1px solid black", marginTop: 'auto', marginBottom: '10px'}}
                        onClick={()=>setModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{color: "black", backgroundColor: "#5DFF4F", border: "1px solid black", marginTop: 'auto', marginBottom: '10px'}}
                        onClick={()=>createScheduleClick()}
                    >
                        Save
                    </Button>
                </div>
                
            </Box>
        </Modal>
    )
}

export default CreateLocationModal