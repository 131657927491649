import React from 'react'
import {Table, TableHead, TableBody, TableRow, TableCell, Container, OutlinedInput} from '@mui/material';
import {useNavigate} from "react-router-dom";

const eventColumns = [
    {field: "event_name", headerName:"Event", flex: 1},
    {field: "start_time", headerName:"Start Time", width: 100},
    {field: "end_time", headerName:"End Time"}
]

function CareerFairTable(props) {
    let navigate = useNavigate();

    
    const {events, user, setChosenEvent} = props
    
    function toggleModal(event) {
        setChosenEvent(event)
    }

    let authorizedUsers = {
        "STUDENT": false,
        "EMPLOYER": false,
        "ADMIN": true
    }
    function handleCareerFairClick(event) {
        if (user.userType == "STUDENT" || user.userType == "EMPLOYER") {
            navigate('/schedule?event=' + event.id)
        }
        if (user.userType == "ADMIN") {
            navigate('/AdminDashboard?event=' + event.id)
        }
    }

    function renderCell(event) {
        if (event.visible == true || user.userType == "ADMIN") {
            return (

                <TableRow key={event.id + '-row'}>
                    <TableCell key={event.id} sx={{display: "flex", justifyContent: "space-between",}}>

                        <div sx={{justifyContent: "space-between", cursor: "pointer"}}>
                            <div>{event.event_name}</div>
                            <div> {event.start_time}</div>
                        </div>


                        <div className='career-fair-buttons'>
                            {user.userType == "ADMIN" ? <span style={{
                                marginTop: "10px",
                                paddingRight: "20px",
                                marginRight: "20px",
                                color: event.visible ? "green" : "gray",
                                borderRight: "solid black 0.5px"
                            }}>{event.visible ? "Visible" : "Hidden"}</span> : <></>}
                            <span
                                onClick={() => handleCareerFairClick(event)}
                                style={{
                                marginTop: "10px",
                                paddingRight: "20px",
                                marginRight: "20px",
                                color: "blue",
                                cursor: "pointer",
                            }}>View Schedules</span>

                            {authorizedUsers[user.userType] ? <span style={{
                                marginTop: "10px",
                                paddingRight: "20px",
                                marginRight: "20px",
                                color: "blue",
                                cursor: "pointer",
                                borderRight: "solid black 0.5px"
                            }} onClick={() => navigate('/locations?event=' + event.id)}
                            >Manage Locations</span> : <></>}
                            {authorizedUsers[user.userType] ?
                                <span style={{marginTop: "10px", marginRight: "20px", color: "blue", cursor: "pointer"}}
                                      onClick={() => toggleModal(event)}
                                >Settings</span> : <></>}
                        </div>

                    </TableCell>
                </TableRow>
            )
        }
    }

    return (
        <Container maxWidth="false" sx={{display: "flex", flexDirection: "column", padding: "16px", width: "100%"}}>
{/*            <DataGrid
                rowHeight={50}
                columns={eventColumns}
                rows={events}
                // Disables a bunch of random stuff that mui defaultly adds
                disableColumnSelector
                disableColumnFilter
                disableEval
                disableMultipleColumnsFiltering
                disableDensitySelector

                slots={{
                    toolbar: GridToolbar,
                    footer: CustomFooter
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        // Again remove the exporting of student data...
                        printOptions: {disableToolbarButton: true},
                        csvOptions: {disableToolbarButton: true},
                    },
                    footer: {}
                }}
                // On select of a row
                onRowClick={(e) => {} }
            />*/}
            <OutlinedInput
                color='primary'
                placeholder="Search..."
                sx={{backgroundColor: 'white', height: "40px", width: "100%"}}
            />
            <Table>
                <TableHead></TableHead>
                <TableBody>
                    {events.map(event => renderCell(event) )}
                </TableBody>
            </Table>
    </Container>
  )
}

export default CareerFairTable;