const pageSize = 500;

async function getAllStudents() {
    return await paginationHelper(`/students?size=${pageSize}`);
}

async function getAllEmployers() {
    return await paginationHelper(`/employers?size=${pageSize}`);
}

async function searchStudents(match) {
    return await sendRequest(`/students?size=${pageSize}&page=1&match=${match}`);
}

async function sendRequest(url, method) {
    const options = {
        method: method,
        headers: {
            'x-access-token': JSON.parse(localStorage.getItem("accessToken"))
        }
    };
    const response = await fetch(process.env.REACT_APP_API+url, options);

    const fullResponse = {
        status: response.status,
        data: await response.json()
    }
    
    return fullResponse;
}

async function paginationHelper(url) {
    
    const response = await sendRequest(url+'&page=1', 'GET');
    const status = response.status;

    if (status != 200) {
        throw Error('Issue retrieving paginated data');
    }

    const { total, results } = response.data.data;
    const totalPages = Math.ceil(total/pageSize);

    const completeData = [];
    completeData.push(...results);

    const batchSize = 10;

    const pages = [];
    for (let i = 2; i <= totalPages; i++) {
        pages.push(i);
    }
    while (pages.length > 0) {
        const promises = [];
        const batch = pages.splice(0, batchSize);
        batch.forEach(page => promises.push(sendRequest(url+`&page=${page}`,'GET')));
        const responses = [...(await Promise.allSettled(promises))];
        const fulfilled = responses.filter((res) => res.status === "fulfilled");

        fulfilled.forEach((current) => completeData.push(...current.value.data.data.results));
    }

    return completeData;
}

export { getAllStudents, getAllEmployers, searchStudents}