import logo from './logo.svg';
import './App.css';
import {useUser} from "./Services/UserService/userContext";
import {Navigate} from "react-router-dom";

function App() {
  const {isAuth, user} = useUser()

  if (!user || user.id == null) {
     return <Navigate to="/login" />
  }

  return <Navigate to="/CareerFairs" />

}

export default App;
