import React, { useContext } from 'react';
import {Button, ListItemButton, ListItemText} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { UserContext } from '../Services/UserService/userContext';
import CreateLocationButton from '../Components/Locations/CreateLocationButton';
import CreateSublocationButton from '../Components/Locations/CreateSublocationButton';
import useFetch from '../Hooks/useFetch';
import LocationDisplay from '../Components/Locations/LocationDisplay';
import PageLayout from "../Components/Layout/PageLayout";

const LocationsPage = () => {
    const {user} = useContext(UserContext)
    let navigate = useNavigate();

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const careerFairId = params.get('event')

    const {data, loading, error} = useFetch(`/locations/${careerFairId}`);
    

    function handleCareerFairClick() {
        navigate("/CareerFairs")
    }

    function handleAdminDashboardClick() {
        navigate(`/AdminDashboard?event=${careerFairId}`)
    }

    if(loading) return <h1>Loading</h1>
    console.log(data.data)

    const sidebarElements = [
        <CreateLocationButton careerFairId={careerFairId} />,
        <CreateSublocationButton careerFairId={careerFairId} locations={data.data} />
    ]

    return(
        <>
            <PageLayout
                sidebarElements={sidebarElements}
            >
                <h2 style={{paddingLeft: '10px'}}>Locations</h2>
                {data.data.map((location) => {return(
                    <LocationDisplay location={location} careerFairId={careerFairId} />
                )})}
            </PageLayout>
            <div
                className="container"
            >
                <div className="nav-bar">
                    <Button onClick={() => handleCareerFairClick()}>Career Fairs</Button>
                    {user.permissionLevel === 3 &&
                        <>
                            <Button onClick={() => handleAdminDashboardClick()}>Admin Dashboard</Button>
                            <Button onClick={() => navigate(`/locationAssign?event=${careerFairId}`)}>Manage Location Assignments</Button>
                        </>
                    }
                </div>
                <div className="page-fill-rows">
                    <div className="left-menu">
                        <CreateLocationButton careerFairId={careerFairId} />
                        <CreateSublocationButton careerFairId={careerFairId} locations={data.data} />
                    </div>
                    <div className="right-fill">
                        <h2 style={{paddingLeft: '10px'}}>Locations</h2>
                        {data.data.map((location) => {return(
                            <LocationDisplay location={location} careerFairId={careerFairId} />
                        )})}
                    </div>
                </div>

            </div>
        </>
    )
}
export default LocationsPage