import React, { useState } from "react"
import {Button, Dialog, DialogActions, DialogTitle, DialogContent} from "@mui/material";
import {Link} from "react-router-dom"
import { apiPut, apiDelete, formatDateAndTime, calculateEndTime } from "../../utilities";
import DeleteConfirmationModal from "../Common/DeleteConfirmationModal";
import {getCurrentUrlParam} from "../../utilities";

import {useNavigate} from "react-router-dom";
function ActivityInformationModal(props) {
    const {
        activity,
        setActivity,
        activityDuration,
        modalOpen,
        setModalOpen,
        user,
        employer,
        student,
        intervieweeName,
        setShowAlert,
        setIsSuccess,
        setSuccessMessage,
        setErrorMessage,
        activityType
    } = props
    //const {deleteActivity} = useContext(ScheduleContext)
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
    const navigate = useNavigate();

    function handleClose() {
        setModalOpen(false)
    }

    async function checkIn() {
        let put_data = {
            activity_id: activity.id
        }
        let response = await apiPut(`/check-in/${activity.id}`, put_data)
        if(response.data) {
            setIsSuccess(true)
            setSuccessMessage('Successfully checked in to interview!')
            setActivity({
                ...activity,
                checked_in: true
            })
        } else {
            setIsSuccess(false)
            setErrorMessage('Error checking in to interview')
        }
        setShowAlert(true)
        setModalOpen(false)
    }

    async function checkOut() {
        let put_data = {
            checked_in: false
        }
        let response = await apiPut(`/activities/${activity.id}`, put_data)
        if(response.data) {
            setIsSuccess(true)
            setSuccessMessage('Successfully checked out of interview!')
            setActivity({
                ...activity,
                checked_in: false
            })
        } else {
            setIsSuccess(false)
            setErrorMessage('Error checking out of interview')
        }
        setShowAlert(true)
        setModalOpen(false)
    }
    function renderBottomButtons() {
        if(user.userType == "STUDENT") {
            return activity.checked_in ? <Button disabled={true} onClick={checkIn}>Checked-in</Button> : <Button onClick={checkIn}>Check-in</Button>
        } else if (user.userType == "ADMIN") {
            return activity.checked_in ? <Button onClick={checkOut}>Check Out</Button> : <Button onClick={checkIn}>Check-in</Button>
        }
    }

    async function handleDeleteClick(activityId) {
        let results = await apiDelete(`/activities/${activityId}`)
        if(results.error) {
            setIsSuccess(false)
            setErrorMessage('Error deleting Activity. Please try again.')
        } else {
            setIsSuccess(true)
            setSuccessMessage('Successfully deleted Activity!')
            //deleteActivity(activityId)
        }
        setShowAlert(true)
        setModalOpen(false)
    }



    function getBlockTitle() {
        if (student.data != undefined) {
            return student.data.first_name +" " + student.data.last_name +'\'s Interview'
        } else {
            return activityType
        }
    }
    function renderEmails() {
        return student.data.emails.map(email => <div>{email.student_email}</div> )
    }
    function renderToEmployerQuickNav() {
        let currUserView = getCurrentUrlParam("scheduleType")
        if (user.userType == "ADMIN" && currUserView.toLowerCase() == "employer") {
            return <Link reloadDocument to={`/schedule?event=${getCurrentUrlParam("event")}&scheduleOwnerId=${student.data.id}&scheduleType=student`}>View Student Schedule</Link>

        }
    }
    function renderToStudentQuickNav() {
        let currUserView = getCurrentUrlParam("scheduleType")
        if (user.userType == "ADMIN" && currUserView.toLowerCase() == "student") {
                return <Link reloadDocument
                             to={`/schedule?event=${getCurrentUrlParam("event")}&scheduleOwnerId=${employer.data.id}&scheduleType=employer`}>View Employer Schedules</Link>

        }
    }

    if(activityType === 'Interview') {
        return(
            <Dialog open={modalOpen}>
                <DialogTitle>
                    {getBlockTitle()}
                    {user.userType === "ADMIN" &&
                        <>
                            <Button
                                sx={{height: "30px", color: "black", backgroundColor: "#ef5350", border: "1px solid black", marginTop: "12px", marginLeft: "10px", marginBottom: '10px'}}
                                onClick={()=>setDeleteConfirmOpen(true)}
                            >
                                Delete
                            </Button>
                            <DeleteConfirmationModal modalOpen={deleteConfirmOpen} setModalOpen={setDeleteConfirmOpen} itemId={activity.id} handleDeleteClick={handleDeleteClick} />
                        </>
                    }    
                </DialogTitle>
                <DialogContent >
                    Description: {activity.description}<br/>
                    Activity Type: {activityType}<br/>
                    Start Time:{formatDateAndTime(activity)}<br/>
                    {activity.schedule ? <>End Time:{calculateEndTime(activity, activityDuration)}<br/></> : <></>}
                    Employer Info:<br/>
                    <div className="employer-info">
                        {`Name: ${employer.data.employer_name} `}
                        {renderToStudentQuickNav()}
                        <br/>

                    </div>
                    Student Info: {renderToEmployerQuickNav()}<br/>
                    <div className="student-info">
                        {`Name: ${student.data.first_name} ${student.data.last_name}`}<br/>
                        {`Phone Number: ${student.data.phone_number}`}<br/>
                        {`Major: ${student.data.major}`}<br/>
                        Emails:<br/>
                        <div className="student-info">
                            {renderEmails()}
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    {(user.userType == "STUDENT" || user.userType == "ADMIN" ) ? renderBottomButtons() : <></>}
                </DialogActions>
            </Dialog>
        )
    } else {
        return(
            <Dialog open={modalOpen}>
                <DialogTitle>
                    {getBlockTitle()}
                    {user.userType == "ADMIN" &&
                        <>
                            <Button
                                sx={{height: "30px", color: "black", backgroundColor: "#ef5350", marginTop: "12px", marginLeft: "10px", marginBottom: '10px'}}
                                onClick={()=>setDeleteConfirmOpen(true)}
                            >
                                Delete
                            </Button>
                            <DeleteConfirmationModal modalOpen={deleteConfirmOpen} setModalOpen={setDeleteConfirmOpen} itemId={activity.id} handleDeleteClick={handleDeleteClick} />
                        </>
                    }
                </DialogTitle>
                <DialogContent>
                    Description: {activity.description}<br/>
                    Activity Type: {activityType}<br/>
                    Start Time:{formatDateAndTime(activity)}<br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }
    
}

export default ActivityInformationModal