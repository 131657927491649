/*
    Creates a simple-to-use function for rendering, fetching, loading, error handling of data from api.

    Author: Brendan Young
 */
import {useState, useRef, useEffect} from "react";

const baseUrl = process.env.REACT_APP_API

export default function useFetch(url) {
    const isMounted = useRef(false)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        isMounted.current = true;
        async function init() {
            try {
                const response = await fetch(baseUrl + url, {
                    method: "GET",
                    headers: {
                        "x-access-token": JSON.parse(localStorage.getItem("accessToken"))
                    }
                });
                if (response.ok) {
                    const json = await response.json();
                    if (isMounted.current) setData(json);
                } else {
                    throw response;
                }
            } catch (e) {
                if (isMounted.current) setError(e);
            } finally {
                if (isMounted.current) setLoading(false);
            }
        }
        init();

        return () => {
            isMounted.current = false;
        };
    }, [url]);

    return {data, error, loading}

}


export function Fetch({url, children}) {
    const {data, loading, error} = useFetch(url);
    return children(data, loading, error)
}