import React, {useState,useEffect, useContext} from "react"
import {Button, FormControl, Select, MenuItem, Box, TextField, Dialog, DialogActions, DialogTitle, DialogContent, List} from "@mui/material";
import {apiPost, getCurrentUrlParam} from "../../utilities";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import useFetch from "../../Hooks/useFetch";
import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext";

import { searchStudents } from "../../Hooks/PaginationHelper";
import StudentResult from "./StudentResult";

import { isMobile } from 'react-device-detect';



const studentColumns = [
    {field: "first_name", headerName:"First Name"},
    {field: "last_name", headerName:"Last Name"},
    {field: "phone_number", headerName:"Phone Number"},
    {field: "major", headerName:"Major"}
]

export function CustomFooter(props) {
    if (props.selectedStudent == null) {
        return (
            <Box sx={{p:1 , display: 'flex'}}>No student selected</Box>
        )
    }
    return (
        <Box sx={{p:1 , display: 'flex'}}>
            Selected Student: {props.selectedStudent.first_name + " " + props.selectedStudent.last_name}
        </Box>
    )
}

function AddActivityModal(props) {
    const {
        modalOpen,
        setModalOpen,
        setShowAlert,
        setIsSuccess,
        setAlertText,
        careerFair,
        user
    } = props
    const {currentSchedule, addActivity} = useContext(ScheduleContext);
    let eventId = getCurrentUrlParam("event")
    const {data: event, loading: eventLoading, error: eventError} = useFetch(`/events/${eventId}`)
    const {data: students, loading: studentsLoading, error: studentsError} = useFetch("/students?page=1&size=100")
    const {data: activities, loading: activitiesLoading, error: activitiesError} = useFetch("/activity-types")

    const [selectedStudent, setSelectedStudent] = useState(null)
    const [currentActivity, setActivity] = useState(null)
    const [pickedTime, setPickedTime] = useState(dayjs('2022-04-17T15:30'))
    const [description, setDescription] = useState("")

    const [search, setSearch] = useState("");
    const [foundStudents, setFoundStudents] = useState([]);

    useEffect(() => {
        if (!eventLoading) {
            setPickedTime(dayjs(event.data.start_time))
        }
        if (activities != null) {
            setActivity(activities[0])
        }
    }, [modalOpen, activities])


    async function searchStudent(student) {
        setSearch(student);
        if(student.length > 1) {
            const data = await searchStudents(student);
            setFoundStudents(data.data.data);
        }
    }

    if (studentsLoading || activitiesLoading || eventLoading) return
    if (studentsError || activitiesError || eventError) return

    async function createActivity() {
        if (currentActivity == null || pickedTime == null || currentSchedule == null) {
            return;
        }
        let newActivityInfo = {
            "activity_type": currentActivity.id,
            "start_time": pickedTime.toISOString(),
            "description": description
        }
        if (selectedStudent != null) {
            newActivityInfo["student_id"] = selectedStudent.id
        }

        let schedule = currentSchedule.id
        let response = await apiPost(`/activities/${schedule}`, newActivityInfo)

        if(response.data) {
            setIsSuccess(true)
            setAlertText("Successfully created activity.")
            addActivity(response.data)
        } else {
            setIsSuccess(false)
            setAlertText(response.message)
        }
        setModalOpen(false)
        setSearch('');
        setSelectedStudent(null);
        setFoundStudents([]);
        setShowAlert(true)
    }
    function canSubmit() {
        if ( (currentActivity != null && description != null ) &&
            ((currentActivity.activity_type == "Interview" && selectedStudent != null) || currentActivity.activity_type != "Interview")
        ) {
            return true
        }
        return false
    }

    function handleClose() {
        setModalOpen(false)
        setSearch('');
        setFoundStudents([]);
        setSelectedStudent(null);
    }
    function handleActivityChange(e) {
        setSelectedStudent(null)
        setActivity(e.target.value)
    }
    function renderActivityMenuItem(activity) {
        return <MenuItem key={activity.id} value={activity}>{activity.activity_type}</MenuItem>
    }

    function handleStudentSelection(student) {
        setSelectedStudent(student);
    }

    function clearStudentSelection() {
        console.log('cleared')
        setSelectedStudent(null);
    }

    return(
        <Dialog
            fullscreen={() => isMobile ? true : false}
            open={modalOpen} fullWidth maxWidth={"lg"}>
            <DialogTitle>Create a new activity</DialogTitle>
            <DialogContent >
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                    }}
                >
                    <FormControl required sx={{ mt: 2, minWidth: 120 }}>
                        <TextField
                            value={currentActivity}
                            select
                            label="Activity Type"
                            helperText="Required"
                            onChange={handleActivityChange}
                        >
                            {activities.data.map(activity => renderActivityMenuItem(activity) )}
                        </TextField>
                    </FormControl>

                    {currentActivity != null && currentActivity.id == 1 ?
                    <>
                    {selectedStudent != null ? 
                    <>
                        <StudentResult callback={handleStudentSelection} clear={clearStudentSelection} selected={selectedStudent?.id} key={selectedStudent.id} student={selectedStudent}/>
                    </> 
                    :    
                    <>
                    <FormControl required sx={{ mt: 2, minWidth: 120 }}>
                        <TextField
                            value={search}
                            label="Student search"
                            onChange={(e) => searchStudent(e.target.value)}
                        >
                            {activities.data.map(activity => renderActivityMenuItem(activity) )}
                        </TextField>
                        </FormControl>


                        {search.length > 2 ? 

                        <List sx={{
                            width: '100%',
                            minWidth: 120,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300,
                            '& ul': { padding: 0 },
                        }}>

                        {foundStudents.map((student) => (
                            <StudentResult callback={handleStudentSelection} clear={clearStudentSelection} selected={selectedStudent?.id} key={student.id} student={student}/>
                        ))}
                        </List>
                         : <></>}
                    
                    </>}

                        </>  
                        :
                        <></>
                    }
                    
                    <FormControl required sx={{ mt: 2, minWidth: 120 }}>
                        <TextField
                            value={description}
                            label="Description"
                            onChange={(e) => setDescription(e.target.value)}
                        >
                            {activities.data.map(activity => renderActivityMenuItem(activity) )}
                        </TextField>
                    </FormControl>
                    <FormControl required sx={{ mt: 2, minWidth: 120 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                                <TimePicker
                                    label="Activity Start Time"
                                    views={['hours', 'minutes']}
                                    value={pickedTime}
                                    onChange={(val) => {
                                        console.log(val)
                                        setPickedTime(val)}
                                }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </FormControl>

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={createActivity} disabled={!canSubmit()} type="submit">Create</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddActivityModal