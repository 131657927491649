import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import LocationAssignmentComponent from './LocationAssignmentComponent';

function SublocationPanel(props) {
    const { index, activeTab, locations, selectedLocation, handleLocationChange, selectedSublocation, handleSublocationChange } = props;

    let locationInfo = selectedLocation ? locations.filter((location) => location.id = selectedLocation?.id) : null
    console.log(locationInfo)
  
    return (
      <div
        role='tabpanel'
        className={`simple-tab-panel`}
        hidden={activeTab !== index}
        aria-labelledby={`simple-tab-${index}`}
    >
        {activeTab === index && (
            <>
                <div className='location-selection-toolbar'>
                    <div className='unassigned-employers-row'>
                        <h2 style={{paddingLeft: '10px', paddingTop: '5px'}}>Pick Location:</h2>
                        <div style={{display: 'flex', width: "60%", padding: "10px"}}>
                            <FormControl sx={{paddingLeft: '10px', width: "50%"}}>
                                <InputLabel id="locations-select-label">Location</InputLabel>
                                <Select
                                labelId="locations-select-label"
                                id="locations-select"
                                value={selectedLocation}
                                label="Parent Location"
                                onChange={handleLocationChange}
                                >
                                    {locations.map((location) => {return(
                                        <MenuItem value={location}>{location.location_name}</MenuItem>
                                    )})}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='assigned-employers-row'>
                        <h2 style={{paddingLeft: '40px', paddingTop: '5px'}}>Pick Sublocation:</h2>
                        <div style={{display: 'flex', width: "60%", padding: "10px"}}>
                            <FormControl sx={{paddingLeft: '10px', width: "50%"}}>
                                <InputLabel id="sublocations-select-label">Sublocation</InputLabel>
                                <Select
                                labelId="sublocations-select-label"
                                id="sublocations-select"
                                value={selectedSublocation}
                                label="Sublocation"
                                onChange={handleSublocationChange}
                                disabled={(selectedLocation == null)}
                                >
                                    {selectedLocation?.sublocations.map((sublocation) => {return(
                                        <MenuItem value={sublocation}>{sublocation.sublocation_name}</MenuItem>
                                    )})}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>

                {(selectedLocation && selectedSublocation) &&
                    <LocationAssignmentComponent location={selectedLocation} sublocation={selectedSublocation} />
                }
          </>
        )}
      </div>
    );
}

export default SublocationPanel;