import React, {useState} from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, Modal, Box } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { apiDelete } from '../../utilities';
import CommonAlert from '../Common/CommonAlert';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';

export default function LocationDisplay(props) {
    const {
        location
    } = props

    const [modalOpen, setModalOpen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('Something went wrong. Please try again.')
    const [successMessage, setSuccessMessage] = useState('Successfully deleted Location!')

    async function handleDeleteClick(location_id) {
        let results = await apiDelete(`/locations/${location_id}`)
        if(results.error) {
            setIsSuccess(false)
            setErrorMessage('Error deleting Location. Please try again.')
        } else {
            setIsSuccess(true)
        }
        setShowAlert(true)
        setModalOpen(false)
    }

  return (
    <div className="location-container">
        <Accordion className="location-accordion">
            <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography sx={{paddingTop: '8px'}}>{location.location_name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography sx={{fontSize: "17px"}}>
                <span style={{fontWeight: "bold"}}>{`${location.location_name}'s Sublocations`}</span>
                {location.sublocations.map((sublocation) => {return(
                    <SublocationComponent sublocation={sublocation} />
                )})}
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Button
            sx={{height: "30px", color: "black", backgroundColor: "#ef5350", border: "1px solid black", marginTop: "12px", marginLeft: "10px", marginBottom: '10px'}}
            onClick={()=>setModalOpen(true)}
        >
            Delete
        </Button>
        <DeleteConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} itemId={location.id} handleDeleteClick={handleDeleteClick} />
        <CommonAlert isSuccess={isSuccess} showAlert={showAlert} setShowAlert={setShowAlert} successMessage={successMessage} errorMessage={errorMessage} />
    </div>
  );
}

function SublocationComponent(props) {
    const {sublocation} = props
    const [modalOpen, setModalOpen] = useState(false)

    function deleteSublocationClick(sublocation_id) {
        apiDelete(`/locations/sublocations/${sublocation_id}`)
        setModalOpen(false)
    }

    return(
        <div className="sublocation-container">
            {`- ${sublocation.sublocation_name}`}
            <Button
                sx={{height: "30px", color: "black", backgroundColor: "#ef5350", border: "1px solid black", marginLeft: "10px", marginTop: 'auto', marginBottom: '10px'}}
                onClick={()=>setModalOpen(true)}
            >
                Delete
            </Button>
            <DeleteConfirmationModal modalOpen={modalOpen} setModalOpen={setModalOpen} location_id={sublocation.id} handleDeleteClick={deleteSublocationClick} sublocation={true} />
        </div>
    )
}