import React, { useEffect, useState } from 'react';
import socket from './socket';
export const SocketContext = React.createContext({});

export const SocketProvider = props => {
    const [currentSocket, setCurrentSocket] = useState(socket);


    useEffect(() => {
        function onConnect() {
            console.log('connection');
        }
      
        function onDisconnect() {
            console.log('disconnection');
        }
    
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
    
        return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        };
    }, [currentSocket]);

    return(
        <SocketContext.Provider value={[currentSocket, setCurrentSocket]}>
            {props.children}
        </SocketContext.Provider>
    );
}