import React, {useState} from "react"
import {Button, Modal, Box, TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import { apiPost } from "../../utilities";


function CreateSublocationModal(props) {
    const {
        modalOpen, 
        setModalOpen,
        setShowAlert,
        setIsSuccess,
        careerFairId,
        locations
    } = props

    const [newSublocationName, setNewSublocationName] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState(null)

    function handleLocationChange(event) {
        setSelectedLocation(event.target.value)
    }

    async function createSublocationClick() {
        let newSublocationInfo = {
            "sublocation_name": newSublocationName,
            }
        let response = await apiPost(`/locations/sublocations/${selectedLocation.id}`, newSublocationInfo)
        response.data ? setIsSuccess(true) : setIsSuccess(false)
        setShowAlert(true)
        setNewSublocationName(null)
        setModalOpen(false)
    }

    return(
        <Modal open={modalOpen} sx={{top: "25%", left: "40%"}}>
            <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: 'white', width: "50%", height: "50%", minHeight: "300px"}}>
                <h3>Create Your New Sublocation</h3>
                
                <div style={{display: 'flex', flexDirection: "column", alignItems: "flex-start", width: '100%'}}>

                    <div style={{display: 'flex', width: '100%', padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                        Select Parent Location:
                        <FormControl sx={{paddingLeft: '10px', width: '40%'}}>
                            <InputLabel id="locations-select-label">Location</InputLabel>
                            <Select
                            labelId="locations-select-label"
                            id="locations-select"
                            value={selectedLocation}
                            label="Parent Location"
                            onChange={handleLocationChange}
                            >
                                {locations.map((location) => {return(
                                    <MenuItem value={location}>{location.location_name}</MenuItem>
                                )})}
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{display: 'flex', padding: "10px", paddingLeft: "21%", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                        Sublocation Name: <TextField id="outlined-basic" label="Sublocation Name" variant="outlined" onChange={(action) => setNewSublocationName(action.target.value)} sx={{paddingLeft: '10px'}} />
                    </div>
                </div>
                <div style={{display: 'flex', width: "45%", marginTop: 'auto', justifyContent: "space-between", paddingBottom: "20px"}}>
                    <Button
                        sx={{color: "black", backgroundColor: "#ECECEC", border: "1px solid black", marginTop: 'auto', marginBottom: '10px'}}
                        onClick={()=>setModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{color: "black", backgroundColor: "#5DFF4F", border: "1px solid black", marginTop: 'auto', marginBottom: '10px'}}
                        onClick={()=>createSublocationClick()}
                    >
                        Save
                    </Button>
                </div>
                
            </Box>
        </Modal>
    )
}

export default CreateSublocationModal