import React, {useContext, useState} from "react";
import {Button, Typography} from "@mui/material";
import AddScheduleModal from "./AddScheduleModal";
import ScheduleCreationAlert from "./ScheduleCreationAlert";
import {UserContext} from "../../Services/UserService/userContext";
import AddIcon from '@mui/icons-material/Add';

function AddScheduleButton(props) {
    const {careerFair, styleName, name, scheduleOwnerId, windowWidth, textDisplay} = props
    const {user} = useContext(UserContext)

    const [modalOpen, setModalOpen] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    function renderText() {
        if (textDisplay) {
            return <Typography variant="h6">{textDisplay}</Typography>
        } else {
            return <AddIcon/>
        }
    }

    return(
        <>
            <Button onClick={()=>setModalOpen(true)} className={styleName}>
                {renderText()}
            </Button>
            <AddScheduleModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                setShowAlert={setShowAlert}
                setIsSuccess={setIsSuccess}
                careerFair={careerFair}
                user={user}
                scheduleOwnerId={scheduleOwnerId}
                windowWidth={windowWidth}
            />
            <ScheduleCreationAlert isSuccess={isSuccess} showAlert={showAlert} setShowAlert={setShowAlert}>
                Successfully Created New Schedule!
            </ScheduleCreationAlert>
        </>

    )
}

export default AddScheduleButton