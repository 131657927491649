import React, { useState, useEffect } from 'react';
import {Container, Tab, Tabs, Button, LinearProgress} from "@mui/material";
import CommonSearchGrid from '../Common/CommonSearchGrid';
import { useNavigate } from 'react-router-dom';
import { getAllEmployers, getAllStudents } from '../../Hooks/PaginationHelper';


const AdminDashboard = () => {
    
    const [activeTab, setActiveTab] = useState(0)
    const [selectedUser, setSelectedUser] = useState(null)

    const [loadingStudents, setLoadingStudents] = useState(false);
    const [loadingEmployers, setLoadingEmployers] = useState(false);

    const [studentData, setStudentData] = useState([]);
    const [employerData, setEmployerData] = useState([]);

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const careerFairId = params.get('event')

    const handleChange = (e, newValue) => {
        setSelectedUser(null)
        setActiveTab(newValue);
    };

    const loadStudents = async() => {
      const data = await getAllStudents();
      setStudentData(data);
      setLoadingStudents(false);
    }

    const loadEmployers = async() => {
      const data = await getAllEmployers();
      setEmployerData(data);
      setLoadingEmployers(false);
    }

    useEffect(() => {
      if(!loadingEmployers) {
        setLoadingEmployers(true);
        console.log('loading employers');
        loadEmployers();
      }
      if(!loadingStudents) {
        setLoadingStudents(true);
        console.log('loading students');
        loadStudents();
      }
    }, []);
    
    return(
        <Container maxWidth="false" sx={{display: "flex", flexDirection: "column", margin: "2.5vh auto", padding: "16px", height: '80vh', width: "90vw", backgroundColor: "#E3E3E3"}}>
            <Tabs value={activeTab} onChange={handleChange} >
                <Tab label='Employer Search' dis='EMPLOYER' {...a11yProps(0)} />
                <Tab label='Student Search' dis='STUDENT' {...a11yProps(1)} />
            </Tabs>
            <SearchPanel
              activeTab={activeTab} 
              targetType={'employer'} 
              targetData={employerData} 
              index={0} 
              careerFairId={careerFairId} 
              selectedUser={selectedUser} 
              setSelectedUser={setSelectedUser} 
              loading={loadingEmployers}
            >
              Employer
            </SearchPanel>
            <SearchPanel 
              activeTab={activeTab} 
              targetType={'student'} 
              targetData={studentData} 
              index={1} 
              careerFairId={careerFairId} 
              selectedUser={selectedUser} 
              setSelectedUser={setSelectedUser} 
              loading={loadingStudents}
            >
              Student
            </SearchPanel>

        </Container>
    )
}
export default AdminDashboard

function SearchPanel(props) {
    const { index, activeTab, targetType, targetData, careerFairId, selectedUser, setSelectedUser, loading } = props;
    let navigate = useNavigate();

    function choosePage() {
        navigate(`/schedule?event=${careerFairId}&scheduleOwnerId=${selectedUser.id}&scheduleType=${targetType}`)
    }

    return (
      <div
        role='tabpanel'
        className={`${targetType}-tab-panel`}
        hidden={activeTab !== index}
        aria-labelledby={`simple-tab-${index}`}
    >
        {activeTab === index && (
          <>
            <div className='search-grid-container'>
              {!loading && <CommonSearchGrid targetType={targetType} targetData={targetData} setSelectedUser={setSelectedUser} />}
              {loading && 
                <div style={{padding: '10px', justifyContent: 'center'}}>
                  <LinearProgress />
                </div>
              }
            </div>
            <div className='view-schedule-button'>
              <Button disabled={selectedUser ? false : true} onClick={() => choosePage()}>View Schedule</Button>
            </div>
          </>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

