import React, { useState } from 'react';
import {FormControl, Box} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";

export default function CommonSearchGrid(props) {
    const {
        targetType,
        targetData,
        setSelectedUser
    } = props

    const [selectedItem, setSelectedItem] = useState(null)

    const targetColumns = targetType === 'student' ? [
        {field: "first_name", headerName:"First Name", width: 150},
        {field: "last_name", headerName:"Last Name", width: 150},
        {field: "phone_number", headerName:"Phone Number", width: 150},
        {field: "major", headerName:"Major", width: 500}
    ] : [
        {field: "employer_name", headerName:"Employer Name", width: 300}
    ]

    function handleRowClick(e) {
        setSelectedItem(e.row)
        setSelectedUser(e.row)
        console.log(e.row)
    }

    function handleSelectionModalChange(e) {
        if (e.length == 0) {
            setSelectedItem(null)
            setSelectedUser(null)
        }
    }

    return (
        <div className={`${targetType}-search-grid-container`}>
            <FormControl required className={`${targetType}-search-grid`}>
                <DataGrid
                    rowHeight={30}
                    columns={targetColumns}
                    rows={targetData}
                    // Disables a bunch of random stuff that mui defaultly adds
                    disableColumnSelector
                    disableColumnFilter
                    disableEval
                    disableMultipleColumnsFiltering
                    disableDensitySelector

                    initialState={{
                        pagination: {
                          paginationModel: { pageSize: 25, page: 0 },
                        },
                      }}

                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            // Again remove the exporting of student data...
                            printOptions: {disableToolbarButton: true},
                            csvOptions: {disableToolbarButton: true},
                        },
                    }}
                    // On select of a row
                    onRowClick={(e) => handleRowClick(e)}
                    // On deselect
                    onRowSelectionModelChange={(e) => handleSelectionModalChange(e)}
                />
            </FormControl>
        </div>
    )
}

function CustomFooter(props) {
    if (props.selectedItem == null) {
        return (
            <Box sx={{p:1 , display: 'flex'}}>{`No ${props.targetType} selected`}</Box>
        )
    }
    return (
        <Box sx={{p:1 , display: 'flex'}}>
            {`Selected ${props.targetType}:`} {props.targetType === 'student' ? props.selectedItem.first_name + " " + props.selectedItem.last_name : props.selectedItem.employer_name}
        </Box>
    )
}