import React, { useContext, useState, useEffect } from "react"

import {
    Box,
 Tabs
} from "@mui/material";
import Tab from '@mui/material/Tab';
import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext";

import { UserContext } from "../../Services/UserService/userContext";
import AddScheduleButton from "./AddScheduleButton";
import {getScheduleUserId} from "../../utilities";

function ScheduleFooter(props) {
    const {eventData, eventId, windowWidth} = props;

    const {user} = useContext(UserContext)
    const {schedules, schedulesLoading, currentSchedule, setCurrentSchedule} = useContext(ScheduleContext)


    useEffect(()=>{}, [schedules,currentSchedule])
    if (schedulesLoading) { return <h1>Loading</h1>}

    if (currentSchedule === null && schedules.length > 0) {
        setCurrentSchedule(schedules[0])
    }

    function handleChange(e, val) {
        if (val != null) setCurrentSchedule(val)

    }

    function renderScheduleTab(schedule) {
        return (<Tab
                    value={schedule}
                    label={schedule.schedule_name}
                />)
    }
     if (schedules != null)
    return (
        <Box sx={{zIndex: '4', display: 'flex', flex: "0 0 auto", width: windowWidth < 950 ? '100%' : `${windowWidth-200}px`, flexFlow: 'row', borderTop: 1, borderColor: 'divider', position: 'sticky', bottom: '0', backgroundColor: 'white'}}>
            <AddScheduleButton careerFair={eventData} name="+" styleName="bottom-tab" scheduleOwnerId={getScheduleUserId(user.internalId)} windowWidth={windowWidth} />
            <Tabs
                value={currentSchedule}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile="true"
                scrollButtons="auto"
                sx={{flexGrow: 1}}
            >
                {schedules.map((schedule) => renderScheduleTab(schedule) )}
            </Tabs>
        </Box>

    )
}

export default ScheduleFooter;