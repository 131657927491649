import React, {useState, useEffect} from 'react'
import CommonSearchGrid from '../Common/CommonSearchGrid';
import useFetch from '../../Hooks/useFetch';
import { Button } from '@mui/material';
import { apiPost, apiDelete, getUnassignedEmployers } from '../../utilities';
import CommonAlert from '../Common/CommonAlert';
import { getAllEmployers } from '../../Hooks/PaginationHelper';

function LocationAssignmentComponent(props) {
    const {location, sublocation} = props

    const [loadingEmployers, setLoadingEmployers] = useState(false);
    const [employerData, setEmployerData] = useState([]);

    const {data: employersAtLocation, loading: employersAtLocationLoading, error: employersAtLocationError} = useFetch(`/employers/locations/${location.id}`)
    const {data: relations, loading: relationsLoading, error: relationsError} = useFetch(`/relationships/sublocations/${location.id}`)

    const [leftSelectedEmployer, setLeftSelectedEmployer] = useState(null)
    const [rightSelectedEmployer, setRightSelectedEmployer] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('Something went wrong. Please try again.')
    const [successMessage, setSuccessMessage] = useState('Employer now assigned to location!')

    const loadEmployers = async() => {
        const data = await getAllEmployers();
        setEmployerData(data);
        setLoadingEmployers(false);
    }
  
    useEffect(() => {
    if(!loadingEmployers) {
        setLoadingEmployers(true);
        console.log('loading employers');
        loadEmployers();
    }
    }, []);

    async function handleAssignEmployerClick() {
        let postBody
        if(sublocation != null) {
            if(relations?.data[0]?.employer) {
                setIsSuccess(false)
                setErrorMessage('Only one employer can be assigned to a sublocation. Please unassign current employer first.')
            } else {
                postBody = {
                    "location_id": location.id,
                    "employer_id": leftSelectedEmployer.id,
                    "sublocation_id": sublocation.id
                }
                let response = await apiPost(`/relationships/employers`, postBody)
                if(response.error) {
                    setErrorMessage('Failed to unassign employer from sublocation. Please try again.')
                    setIsSuccess(false)
                } else {
                    setSuccessMessage('Employer successfully assigned to sublocation!')
                    setIsSuccess(true)
                }
            }
        } else {
            postBody = {
                "location_id": location.id,
                "employer_id": leftSelectedEmployer.id,
            }
            let response = await apiPost(`/relationships/employers`, postBody)
            if(response.error) {
                setErrorMessage('Failed to unassign employer to location. Please try again.')
                setIsSuccess(false)
            } else {
                setSuccessMessage('Employer successfully assigned to location!')
                setIsSuccess(true)
            }
        }
        setShowAlert(true)
    }

    async function handleUnassignEmployerClick() {
        let postBody
        if(sublocation != null) {
            postBody = {
                "employer_id": rightSelectedEmployer.id,
                "sublocation_id": sublocation.id
            }
            let response = await apiDelete(`/relationships/sublocations`, postBody)
            if(response.error) {
                setErrorMessage('Failed to unassign employer from sublocation. Please try again.')
                setIsSuccess(false)
            } else {
                setSuccessMessage('Employer successfully unassigned from sublocation!')
                setIsSuccess(true)
            }
        } else {
            postBody = {
                "location_id": location.id,
                "employer_id": rightSelectedEmployer.id,
            }
            let response = await apiDelete(`/relationships/locations`, postBody)
            if(response.error) {
                setErrorMessage('Failed to unassign employer from location. Please try again.')
                setIsSuccess(false)
            } else {
                setSuccessMessage('Employer successfully unassigned from location!')
                setIsSuccess(true)
            }
        }
        setShowAlert(true)
    }

    if(loadingEmployers || employersAtLocationLoading || relationsLoading) return <h1>Loading</h1>
    let unassignedEmployers = getUnassignedEmployers(employerData, employersAtLocation.data)
    let employerAtSublocation

    if(sublocation) {
        employerAtSublocation = relations?.data[0]?.employer ? [relations?.data[0]?.employer] : []
        
        if (employerAtSublocation != null) {unassignedEmployers = getUnassignedEmployers(employerData, employerAtSublocation)}
    }

    return(
        <div className='employer-assignment-container'>
            <div className='unassigned-employers-list'>
                {sublocation ? <h3>Employers not assigned to this sublocation</h3> : <h3>Employers not assigned to this location</h3>}
                <CommonSearchGrid targetType='employer' targetData={unassignedEmployers} setSelectedUser={setLeftSelectedEmployer} />
                <Button disabled={(leftSelectedEmployer === null)} onClick={() => handleAssignEmployerClick()} >Assign to Location</Button>
            </div>
            
            <div className='assigned-employers-list'>
                <h3>Employers at Location</h3>
                <CommonSearchGrid targetType='employer' targetData={sublocation ? employerAtSublocation : employersAtLocation.data} setSelectedUser={setRightSelectedEmployer} />
                <Button disabled={(rightSelectedEmployer === null)} onClick={() => handleUnassignEmployerClick()} >Unassign from Location</Button>
            </div>
            <CommonAlert isSuccess={isSuccess} showAlert={showAlert} setShowAlert={setShowAlert} successMessage={successMessage} errorMessage={errorMessage} />
        </div>
    )
}

export default LocationAssignmentComponent;