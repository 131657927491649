import React, { useContext, useState, useEffect } from "react"

import {
    Box,
    Typography,
    IconButton,
    TextField, Grid
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext";

import {getScheduleUserId} from "../../utilities";
import { UserContext } from "../../Services/UserService/userContext";
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteConfirmationModal from "../Common/DeleteConfirmationModal";
import AddScheduleButton from "./AddScheduleButton";

function ScheduleHeader(props) {
    const {
        eventData, windowWidth
    } = props;
    const {user} = useContext(UserContext)
    const {schedules, currentSchedule, setCurrentSchedule, editScheduleName} = useContext(ScheduleContext)
    const [editing, setEditing] = useState(false)
    const [name, setName] = useState("")

    const {deleteSchedule} = useContext(ScheduleContext)
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

    if (currentSchedule === null && schedules.length > 0) {
        setCurrentSchedule(schedules[0])
    }
    useEffect(() => {

        if (currentSchedule != null && name != currentSchedule.schedule_name) {
            setEditing(false)
            setName(currentSchedule.schedule_name)
        }
    }, [currentSchedule])

    const userPerms = {
        "ADMIN": true,
        "EMPLOYER": true,
        "STUDENT": false
    }


    async function saveName() {
        let newSchedule = await editScheduleName(name);
        setEditing(false)
    }

    function handleDeleteClick() {
        try {
            deleteSchedule(currentSchedule.id)
            setDeleteConfirmOpen(false)

        } catch(e) {

        }
    }
    const deleteIcon = (
        <IconButton onClick={() => {
            setDeleteConfirmOpen(true)
        }}>
            <DeleteIcon />
        </IconButton>
    )

    function getDeleteIcon() {
        if (user.userType == "ADMIN") {
            return deleteIcon
        } else if (user.userType == "EMPLOYER") {
            let hasInterview = currentSchedule.activities.find(a => a.activity_type == 1)
            if (currentSchedule != undefined && currentSchedule.activities.length >= 0 && hasInterview == undefined) {
                return deleteIcon
            }
        }
    }

    function renderEditCell() {
        return (
            <>
                <TextField
                    id="scheduleName"
                    label={"Schedule Name"}
                    value={name}
                    onChange={(event ) => setName(event.target.value)}
                />
                <IconButton onClick={() => saveName(true)}>
                    <SaveIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    setEditing(false) }
                }>
                    <ClearIcon/>
                </IconButton>
            </>
        )
    }

    function renderDisplayCell() {
        if (schedules.length == 0) {
            return <>
                <Typography variant="h5" gutterBottom textAlign="center">No Schedules</Typography>
                <AddScheduleButton careerFair={eventData} name="+" styleName="bottom-tab" scheduleOwnerId={getScheduleUserId(user.internalId)} windowWidth={windowWidth} textDisplay="CREATE" />
            </>
        }
        return (
            <>
                <Typography variant="h5" gutterBottom textAlign="center">{currentSchedule ? currentSchedule.schedule_name : ""}</Typography>
                <IconButton onClick={() => {
                    setName(currentSchedule.schedule_name)
                    setEditing(true)
                }}>
                    <EditIcon/>
                </IconButton>
                {getDeleteIcon()}
            </>
        )
    }

    function canEdit() {
        try {
            return userPerms[user.userType]
        } catch (e) {
            return false
        }
    }

    function renderHeaderCells() {
        if (canEdit()) {
            return editing ? renderEditCell() : renderDisplayCell()
        }
        let text = currentSchedule ? currentSchedule.schedule_name : ""
        if (user.userType == "STUDENT") {
            text = "Student Schedule"
        }
        return (
            <Typography variant="h5" gutterBottom textAlign="center">{text}</Typography>
        )
    }

    return (
        <Box sx={{flexFlow: 'column', paddingTop: '8px', flex: "0 0 auto"
        }}>
            <Grid container margin={0}
                sx={{            justifyContent:"center",
                    alignItems: "center",
                display: 'flex'}}
            >
                { renderHeaderCells()}

            </Grid>
            <DeleteConfirmationModal modalOpen={deleteConfirmOpen} setModalOpen={setDeleteConfirmOpen} currentSchedule={currentSchedule} handleDeleteClick={handleDeleteClick} />
        </Box>

    )
}

export default ScheduleHeader;