import React  from "react"
import {
    Box, Typography
} from "@mui/material";

function HourBlock(props) {
    const startTime = new Date(props.startTime)
    const blockSize = props.blockSize
    const topOffset = props.blockSize * -1 + 5
    const leftOffset = 0
    const isAm = (startTime.getHours()-1) < 12
    let displayHour = (startTime.getHours()-1) % 12

    if (displayHour == 0) {
        displayHour = 12
    }


    return (
        <Box sx={{
            height: blockSize+"px",
            alignItems: 'baseline',
            display: 'flex',
            flexFlow: 'row'
            //borderTop:'1px solid rgba(0, 0, 0, 0.2)',
        }}>

            <Typography sx={{
                position: 'relative',
                width: '40px',
                fontSize: "12px",
                top: topOffset,
                left: leftOffset,

            }}>{displayHour} {isAm ? "AM" : "PM"}</Typography>
            <Typography sx={{
                height: '100%',
                width: '20px',
                borderRight:'1px solid rgba(0, 0, 0, 0.2)',
                borderTop:'1px solid rgba(0, 0, 0, 0.2)',
            }}></Typography>
            <Box
                sx={{
                    height: '100%',
                    flexGrow: 1,
                    borderTop:'1px solid rgba(0, 0, 0, 0.2)',
                }}
            />
        </Box>
    )
}
export default HourBlock;