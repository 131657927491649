export async function loginPost(url, bodyData) {
    let baseUrl = process.env.REACT_APP_API
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(bodyData)
    }
    try {
        const response = await fetch(baseUrl + url, options);
        if (response.ok) {
            const json = await response.json();
            return json
        } else {
            throw response;
        }
    } catch (e) {
        
    } finally {
        
    }
}
export function convertDayDiffToHours(date1, date2) {
    return Math.abs(date1 - date2) / 36e5
}

export function minutesDiff(dateTimeValue2, dateTimeValue1) {
    var diff = Math.abs( dateTimeValue2 - dateTimeValue1 )
    return Math.floor((diff/1000)/60);
}

export async function apiGet(url) {
    let baseUrl = process.env.REACT_APP_API
    let options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "x-access-token": JSON.parse(localStorage.getItem("accessToken"))
        },
    }
    try {
        const response = await fetch(baseUrl + url, options);
        if (response.ok) {
            const json = await response.json();
            return json
        } else {
            throw response;
        }
    } catch (e) {
        
    } finally {
        
    }
}

export async function apiPost(url, bodyData) {
    let baseUrl = process.env.REACT_APP_API
    let options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "x-access-token": JSON.parse(localStorage.getItem("accessToken"))
        },
        body: JSON.stringify(bodyData)
    }
    try {
        const response = await fetch(baseUrl + url, options);
        if (response.ok) {
            const json = await response.json();
            return json
        } else {
            if(response.status == 422) {
                const json = await response.json();
                return json
            }
            throw response;
        }
    } catch (e) {
        
    } finally {
        
    }
}

export async function apiPut(url, bodyData) {
    let baseUrl = process.env.REACT_APP_API
    let options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "x-access-token": JSON.parse(localStorage.getItem("accessToken"))
        },
        body: JSON.stringify(bodyData)
    }
    try {
        const response = await fetch(baseUrl + url, options);
        if (response.ok) {
            const json = await response.json();
            return json
        } else {
            throw response;
        }
    } catch (e) {
        
    } finally {
        
    }
}

export function getCurrentUrlParam(param) {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const item = params.get(param)
    return item;
}

export function getScheduleUserId(internalId) {
    let ownerId = getCurrentUrlParam("scheduleOwnerId")
    if (ownerId) {
        return ownerId
    }
    return internalId
}

export async function apiDelete(url, bodyData) {
    let baseUrl = process.env.REACT_APP_API
    let options = bodyData ? {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "x-access-token": JSON.parse(localStorage.getItem("accessToken"))
        },
        body: JSON.stringify(bodyData)
    } : 
    {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "x-access-token": JSON.parse(localStorage.getItem("accessToken"))
        }
    }
    try {
        const response = await fetch(baseUrl + url, options);
        if (response.ok) {
            const json = await response.json();
            return json
        } else {
            throw response;
        }
    } catch (e) {
        return null;
    } finally {

    }
}

export function formatDateAndTime(activity) {
    let time = new Date(activity.start_time);
    return time.toLocaleString()
/*    const months = {
        "01": 'January',
        "02": 'February',
        "03": 'March',
        "04": 'April',
        "05": 'May',
        "06": 'June',
        "07": 'July',
        "08": 'August',
        "09": 'September',
        "10": 'October',
        "11": 'November',
        "12": 'December'
    }
    let information = activity.start_time.split('T')
    let dateInfo = information[0].split('-')
    let timeInfo = information[1].split(':')
    let endingTag = 'AM'
    if (timeInfo[0] > 12) {
        timeInfo[0] = timeInfo[0] - 12
        endingTag = 'PM'
    }
    return ` ${timeInfo[0]}:${timeInfo[1]} ${endingTag} ${months[dateInfo[1]]} ${dateInfo[2]}, ${dateInfo[0]}`*/
}

export function calculateEndTime(activity, activityDuration) {
    let startTimeInfo
    let endingTag = 'AM'
    if (activityDuration < 60) {
        startTimeInfo = activity.start_time.split('T')[1].split(':')
        if (parseInt(startTimeInfo[0]) > 12) {
            startTimeInfo[0] = startTimeInfo[0] - 12
            endingTag = 'PM'
        }
        startTimeInfo[1] = parseInt(startTimeInfo[1]) + activityDuration
    }
    
    return ` ${startTimeInfo[0]}:${startTimeInfo[1]} ${endingTag}`
}

export function getUnassignedEmployers(employers, assignedEmployers) {
    let assignedEmployerIds = []

    for(let assignedEmployer in assignedEmployers) {
        assignedEmployerIds.push(assignedEmployers[assignedEmployer].employer_symplicity_id)
    }

    return employers.filter((employer) => !assignedEmployerIds.includes(employer.employer_symplicity_id))
}

