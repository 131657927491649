import React from 'react';
import AdminDashboard from '../Components/AdminDashboard/AdminDashboard';
import PageLayout from "../Components/Layout/PageLayout";


const AdminDashboardPage = () => {
    return(
        <PageLayout>
            <AdminDashboard />
        </PageLayout>
    )
}
export default AdminDashboardPage