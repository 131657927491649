import React, {useState} from 'react'
import { Alert, AlertTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const errorCodes = {
    200: "success",
    401: "unauthorized",
}


function CommonAlert(props) {
    //const [open, setOpen] = useState(false)

    const {
        severity,
        showAlert,
        setShowAlert,
        alertTitle,
        alertMessage,
    } = props

    return (
        <Alert
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setShowAlert(false);
                    }}
                >

                    <CloseIcon fontSize="inherit" onClick={() => {setShowAlert(false)}} />
                </IconButton>
            }
            sx={{position: 'fixed', zIndex: 999, left: '40%', bottom: '5%', visibility: showAlert ? 'visible' : 'hidden'}}
            severity={severity}
            onClose={() => {setShowAlert(false)}}

        >
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
        </Alert>
    )

}

export default CommonAlert