import React, { useContext, useState } from 'react';
import {Button, FormControl, InputLabel, Select, MenuItem, Container, Tabs, Tab} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { UserContext } from '../Services/UserService/userContext';
import CreateLocationButton from '../Components/Locations/CreateLocationButton';
import CreateSublocationButton from '../Components/Locations/CreateSublocationButton';
import useFetch from '../Hooks/useFetch';
import LocationPanel from '../Components/Locations/LocationsPanel';
import SublocationPanel from '../Components/Locations/SublocationsPanel';
import PageLayout from "../Components/Layout/PageLayout";

const LocationAssignPage = () => {
    const {user} = useContext(UserContext)
    let navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0)
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [selectedSublocation, setSelectedSublocation] = useState(null)
    

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const careerFairId = params.get('event')

    const {data, loading, error} = useFetch(`/locations/${careerFairId}`);

    const handleChange = (e, newValue) => {
        setActiveTab(newValue);
      };

    function handleCareerFairClick() {
        navigate("/CareerFairs")
    }

    function handleAdminDashboardClick() {
        navigate(`/AdminDashboard?event=${careerFairId}`)
    }

    function handleLocationChange(event) {
        setSelectedLocation(event.target.value)
    }

    function handleSublocationChange(event) {
        setSelectedSublocation(event.target.value)
    }

    if(loading) return <h1>Loading</h1>
    console.log(data.data)

    const sidebarElements = [
        <CreateLocationButton careerFairId={careerFairId} />,
        <CreateSublocationButton careerFairId={careerFairId} locations={data.data} />
    ]

    return(
        <PageLayout sidebarElements={sidebarElements}>

                        <div className="page-fill-rows">
                        <Container maxWidth="false" sx={{display: "flex", flexDirection: "column", margin: "2.5vh auto", padding: "16px", height: '100vh', width: "90vw"}}>
                                <Tabs value={activeTab} onChange={handleChange} >
                                    <Tab label='Assign Employers to Location' {...a11yProps(0)} />
                                    <Tab label='Assign Employers to Sublocation' {...a11yProps(1)} />
                                </Tabs>
                                <LocationPanel
                                activeTab={activeTab} 
                                index={0} 
                                locations={data.data}
                                selectedLocation={selectedLocation}
                                handleLocationChange={handleLocationChange}
                                >
                                Location
                                </LocationPanel>
                                <SublocationPanel 
                                activeTab={activeTab} 
                                index={1} 
                                locations={data.data}
                                selectedLocation={selectedLocation}
                                handleLocationChange={handleLocationChange}
                                selectedSublocation={selectedSublocation}
                                handleSublocationChange={handleSublocationChange}
                                >
                                Sublocation
                                </SublocationPanel>

                            </Container>
                        </div>
        </PageLayout>
    )
}
export default LocationAssignPage

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }