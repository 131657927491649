import React, {useContext, useState} from "react";
import '../../css/schedule.css'
import ActivityInformationModal from "./ActivityInformationModal";
import { UserContext } from "../../Services/UserService/userContext";
import CommonAlert from "../Common/CommonAlert";
import {Dialog, Typography} from "@mui/material";
import useFetch from "../../Hooks/useFetch";
import {formatDateAndTime, minutesDiff} from "../../utilities";
import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext";

import {
    Box, Card
} from "@mui/material";
ActivityBlockStudent.defaultProps = {
    initialActivity: []
}

function ActivityBlockStudent(props) {
    const {
        initialActivity,
        blockSize,
        event,
        student,
        studentLoading,
        activityTypes,
        activityTypesLoading,
    } = props
    const {user} = useContext(UserContext);
    const [activity, setActivity] = useState(initialActivity)
    const {data: employer, loading: employerLoading} = useFetch(`/employers/${activity.employer_id}`)

    const [modalOpen, setModalOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('Checked-in to Interview!')
    const [errorMessage, setErrorMessage] = useState('Failed to check-in. Try again.')

    let startTime = formatDateAndTime(activity).split(", ")[1].split(":")
    //let startTimeOld = activity.start_time.split('T')[1].split(":")
    let activityDuration = activity.schedule.activity_duration
    let height = (activityDuration/60)*blockSize

    // get currentSchedule offset time + activity start time and make the height be equal to blocksize / duration in relation to hours
    // topOffset = timeDiff of current schedule & activity start
    let timeDiff = minutesDiff(new Date(event.data.start_time), new Date(activity.start_time))
    let topOffset = timeDiff/60 *  blockSize;

    if(studentLoading || employerLoading || activityTypesLoading) return <Dialog open={modalOpen}><h1>Loading</h1></Dialog>

    let activityType = activityTypes.data.filter((type) => type.id === activity.activity_type)[0].activity_type

    let backgroundColor = activity.checked_in ? "#76ff03" : "#e0e0e0"

    const fontSize = 12

    let blockTitle = "Interview with " + employer.data.employer_name
    let blockDescription = activity.description

    return(
        <>
                    <Box sx={{
                        width: "100%",
                        height: height+"px",
                        display: "flex",
                        position: 'absolute',
                        fontSize: "12px",
                        top: topOffset,
                    }}
                    >
                        <Box sx={{width: "65px"}}/>
                        <Card
                            sx={{
                                flexGrow: 1,
                                cursor: "pointer",
                                backgroundColor: backgroundColor
                            }}
                            onClick={() => setModalOpen(true)}
                        >
                            {/*
                        >{activity.description ?? `Interview with ${user.permissionLevel === 1 ? employer.data.employer_name : user.permissionLevel === 2 ? `${student.data.first_name} ${student.data.last_name}` : `${student.data.first_name} ${student.data.last_name} and ${employer.data.employer_name}`}`}
*/}
                            <Typography bold fontSize={fontSize}>{blockTitle}</Typography>
                            <Typography fontSize={fontSize}>{blockDescription}</Typography>
                        </Card>
                    </Box>

                    <ActivityInformationModal
                        activity={activity}
                        setActivity={setActivity}
                        activityDuration={activityDuration}
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        user={user}
                        employer={employer}
                        student={student}
                        intervieweeName={employer.data.employer_name}
                        setShowAlert={setShowAlert}
                        setIsSuccess={setIsSuccess}
                        setSuccessMessage={setSuccessMessage}
                        setErrorMessage={setErrorMessage}
                        activityType={activityType}
                    />
                    <CommonAlert
                        isSuccess={isSuccess}
                        showAlert={showAlert}
                        setShowAlert={setShowAlert}
                        alertMessage={isSuccess ? successMessage : errorMessage}

                    />
        </>

    )
}

export default ActivityBlockStudent