import React from 'react'
import {
    AppBar,
    Box,
    Menu,
    MenuItem,
    Typography,
    IconButton,
    Toolbar,
    List,
    ListItem,SwipeableDrawer
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {useUser} from "../../Services/UserService/userContext";
import {AccountCircle} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {getCurrentUrlParam} from "../../utilities";

function PageLayout(props) {
    let {
        sidebarElements, navbarElements
    } = props;
    let navigate = useNavigate();
    const drawerWidth = 200

    let {user, userDispatch} = useUser()
    const [anchorEl, setAnchorEl] = React.useState(false);

    let displayName = "";

    //set windowWidth to the current width of the window
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
    //add a listener to the window object to change windowWidth whenever the window is resized
    React.useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const [open, setOpen] = React.useState(false)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    function handleLogout() {
        userDispatch({ type: "signOut" })
        navigate('/login')
    }
    const handleClose = () => {
        setAnchorEl(null);
    };


    function renderNavbarElements() {
        if (user.userType == "ADMIN" && getCurrentUrlParam("event") != null) {
            const page = window.location.pathname.split('/')[1];
            return (
                <>
                    <MenuItem disabled={page == "AdminDashboard"} key="admindb" disablePadding onClick={() => navigate(`/AdminDashboard?event=${getCurrentUrlParam("event")}`)}>
                        <Typography>View Schedules</Typography>
                    </MenuItem>
                    <MenuItem disabled={page == "locationAssign"} key="locationdb" disablePadding onClick={() => navigate(`/locationAssign?event=${getCurrentUrlParam("event")}`)}>
                        <Typography>Assign Locations</Typography>
                    </MenuItem>
                    <MenuItem disabled={page == "locations"} key="locations" disablePadding onClick={() => navigate(`/locations?event=${getCurrentUrlParam("event")}`)}>
                        <Typography>Manage Locations</Typography>
                    </MenuItem>
                </>
            )
        }

        if (navbarElements == null) {
            return;
        }
        return navbarElements.map((element) => {
            return <MenuItem key={element.key} disablePadding onClick={() => element.onClick()}>
                        <Typography>{element.display}</Typography>
                    </MenuItem>
        })
    }
    function renderSidebarElements() {
        if (sidebarElements == null) {
            return;
        }
        return sidebarElements.map((element) => {
            return <ListItem key={element.key} disablePadding>
                        {element}
                    </ListItem>
        })
    }

    const renderChildren = () => {
        return React.Children.map(props.children, (child) => {
            return React.cloneElement(child, {
              windowWidth: windowWidth,
            });
        });
    }
    return(
        <Box sx={{flexGrow: 1, display: 'flex', flexFlow: 'column', height: '100vh', width: '100vw'}}>
            <AppBar position="sticky" sx ={{ height: '70px', zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                {(windowWidth < 950) &&
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(!open)}
                        edge="start"
                        sx={{ mr: 2, ...((sidebarElements == null) && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                }
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>{displayName}</Typography>
                 {renderNavbarElements()}
                        <MenuItem disabled={window.location.pathname.split('/')[1] == "CareerFairs"} key="careerFair" disablePadding onClick={() => navigate("/CareerFairs")}>
                            <Typography>Career Fairs</Typography>
                        </MenuItem>
                <Box >
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleMenu}
                    >
                        <AccountCircle/>
                    </IconButton>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={anchorEl}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{flexGrow: 1, display: 'flex', flexFlow: 'row', height: '100%'}}>
                {sidebarElements == null ? <></> : <SwipeableDrawer
                    variant={(windowWidth > 950) ? "permanent" : "persistent"}
                    anchor="left"
                    open={open}
                    sx={{
                        marginTop: '70px',
                        width: (windowWidth > 950) ? drawerWidth : 0,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }
                    }}
                >
                    {(windowWidth < 950) &&
                        <div style={{display: 'flex', alignItems: 'center', padding: '35% 0px 0px 10px', justifyContent: 'flex-end'}}>
                            <IconButton onClick={() => setOpen(false)}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                    }
                    <Box sx={{overflow: 'auto', marginTop: (windowWidth > 950) ? '70px' : '0px'}}>
                        <List>
                            {renderSidebarElements()}
                        </List>
                    </Box>
                </SwipeableDrawer> }
                <Box sx={{flexGrow: 1, width: '100%'}}>
                    {renderChildren()}
                </Box>
            </Box>
        </Box>
    )
}

export default PageLayout