import React, {useState, useContext} from "react"
import {Button, Modal, Box, TextField, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import { apiPost } from "../../utilities";
import {ScheduleContext} from "../../Services/ScheduleService/scheduleContext"


function AddScheduleModal(props) {
    const {
        modalOpen, 
        setModalOpen,
        setShowAlert,
        setIsSuccess,
        careerFair,
        user,
        scheduleOwnerId,
        windowWidth
    } = props
    const { addSchedule} = useContext(ScheduleContext)
    const eventsDurationOptions = careerFair.data.activity_durations
    const mobile = windowWidth < 950

    const [newScheduleName, setNewScheduleName] = useState(null)
    const [newScheduleActivityDuration, setNewScheduleActivityDuration] = useState(eventsDurationOptions[0].activity_duration)

    async function createScheduleClick(scheduleName, activityDuration) {
        let newScheduleInfo = {
            "schedule_name": scheduleName,
            "activity_duration": parseInt(activityDuration)
            }
        let response = await apiPost(`/schedules/${careerFair.data.id}/${scheduleOwnerId ? scheduleOwnerId : user.internalId}`, newScheduleInfo)
        response.data ? setIsSuccess(true) : setIsSuccess(false)
        if (response.data) {
            addSchedule(response.data)
        }

        setShowAlert(true)
        setNewScheduleName(null)
        setNewScheduleActivityDuration(eventsDurationOptions[0].activity_duration)
        setModalOpen(false)
    }

    return(
        <Modal open={modalOpen} sx={{top: "25%", left: mobile ? "10%" : "40%"}}>
            <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: 'white', width: mobile ? "90%" : "50%", height: "60%", minHeight: "300px"}}>
                <h3>Create Your New Schedule</h3>
                <Button onClick={() => setModalOpen(false)}sx={{fontWeight: 'bold', position: 'fixed', right: mobile ? '7.5%' : '30%', top: '25%', color: 'red', fontSize: "20px"}}>X</Button>
                <div style={{display: 'flex', flexDirection: "column", alignItems: "flex-start"}}>
                    <div style={{display: 'flex', padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                        Schedule Name: <TextField id="outlined-basic" label="Schedule Name" variant="outlined" onChange={(action) => setNewScheduleName(action.target.value)} sx={{paddingLeft: '10px'}} />
                    </div>
                    <div style={{display: 'flex', padding: "10px", justifyContent: "center", alignItems: 'flex-start'}}>
                        <span style={{padding: '10px 10px 10px 0px'}}>Activity Duration: </span>
                        <RadioGroup defaultValue={eventsDurationOptions[0].activity_duration} onChange={(action) => setNewScheduleActivityDuration(action.target.value)}>
                            {eventsDurationOptions.map((durationOption) => {
                                return (
                                    <FormControlLabel key={durationOption.id} value={durationOption.activity_duration} control={<Radio />} label={`${durationOption.activity_duration} minutes`} />
                                )
                            })}
                        </RadioGroup>
                    </div>
                </div>
                <Button
                    sx={{color: "black", border: "1px solid black", marginTop: 'auto', marginBottom: '10px'}}
                    onClick={()=>createScheduleClick(newScheduleName, newScheduleActivityDuration)}
                >
                    Add Schedule
                </Button>
            </Box>
        </Modal>
    )
}

export default AddScheduleModal