/*
    Logic for user should be in here.

    Anything that requires updating the variable user will be in here.

    Author: Brendan Young
 */

    export default function userReducer(user, action) {

        switch(action.type) {
            case "signIn": {
                return {...user, id: action.id, internalId: action.internalId, accessToken: action.accessToken, userType: action.userType}
            }
            case "signOut": {
                localStorage.removeItem("userId")
                localStorage.removeItem("internalId")
                localStorage.removeItem("accessToken")
                return {...user, id: null, internalId: null, accessToken: null}
            }
            default:
                throw new Error("Unhandled action " +action.type)
        }
    
    }