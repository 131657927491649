import React, {useEffect, useState} from "react"
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, FormGroup, FormControlLabel, Checkbox, Alert} from "@mui/material";
import { apiPut } from "../../utilities";



function EditCareerFairModal(props) {
    const {
        chosenEvent,
        modalOpen,
        setModalOpen,
        setShowAlert,
        setIsSuccess,
        setEventWasUpdated,
    } = props
    const [loading, setLoading] = useState(true)
    const [newEventName, setNewEventName] = useState(chosenEvent.event_name)
    const [newEventActivityDurations, setNewEventActivityDurations] = useState(chosenEvent.activity_durations)
    const [visible, setVisible] = useState(chosenEvent.visible)
    const [addedDuration, setAddedDuration] = useState(0)
    const [showAddAlert, setShowAddAlert] = useState(false)
    const [alertText, setAlertText] = useState('This Duration is already allowed for this Event!')

    useEffect(() => {
        if ( loading && newEventActivityDurations != undefined ) {
            setLoading(false)
        }
    }, [])

    function removeDuration(boxChanged) {
        let updatedDurations = newEventActivityDurations.filter((duration) => duration.activity_duration != parseInt(boxChanged.name))

        setNewEventActivityDurations(updatedDurations)
        boxChanged.checked = !boxChanged.checked
    }

    function addDuration(addedDuration) {
        let alreadyExistingDuration = newEventActivityDurations.filter((duration) => duration.activity_duration == addedDuration)
        if (alreadyExistingDuration.length == 0) {
            if (isNaN(addedDuration)) {
                setAlertText("Please enter an integer amount of minutes.")
                setShowAddAlert(true)
            } else {
                let addedDurationObject = {
                    id: null,
                    event_id: chosenEvent.id,
                    activity_duration: addedDuration
                }
                let updatedDurations = newEventActivityDurations.concat([addedDurationObject])
                setNewEventActivityDurations(updatedDurations)
            }
        } else {
            setAlertText("This Duration is already allowed for this Event!")
            setShowAddAlert(true)
        }
    }
    

    async function saveButtonClick() {
        if (newEventActivityDurations.length > 0) {
            let formattedActivityDurations = []
            for(let activityDuration in newEventActivityDurations) {
                formattedActivityDurations.push(newEventActivityDurations[activityDuration].activity_duration)
            }
            let newEventInfo = {
                "event_symplicity_id": chosenEvent.event_symplicity_id,
                "event_name": newEventName,
                "activity_durations": formattedActivityDurations,
                "visible": visible
            }
            let response = await apiPut(`/events/${chosenEvent.id}`, newEventInfo)
            if(response.data) {
                setIsSuccess(true)
                setEventWasUpdated(true)
            } else {
                setIsSuccess(false)
            }
        }
        setShowAlert(true)
        setModalOpen(false)
    }

    function cancelButtonClick() {
        setLoading(true)
        setNewEventName(chosenEvent.event_name)
        setNewEventActivityDurations(chosenEvent.activity_duration)
        setModalOpen(false)
    }

    if ( loading ) {
        return(
            <Dialog open={modalOpen} sx={{top: "25%", left: "32.5"}}>
                <Box sx={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "white", width: "50%", height: "50%", minHeight: "300px"}}>
                    <h1>Loading</h1>
                </Box>
            </Dialog>
        )
    }

    return(
        <Dialog open={modalOpen}>
            <DialogTitle>{chosenEvent.event_name} Settings</DialogTitle>
            <DialogContent >
                <div style={{display: 'flex', flexDirection: "column", alignItems: "flex-start"}}>
                    <div style={{display: 'flex', padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                        Career Fair Name: <TextField id="outlined-basic" label="Career Fair Name" defaultValue={chosenEvent.event_name} variant="outlined" onChange={(action) => setNewEventName(action.target.value)} sx={{marginLeft: '90px'}} />
                    </div>
                    <div style={{display: 'flex', padding: "10px", justifyContent: "center", alignItems: 'flex-start'}}>
                        <span style={{padding: '10px 25px 10px 0px'}}>Allowed Activity Durations: </span>
                        <FormGroup>
                            {newEventActivityDurations.map((activity_duration) => {
                                return(
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={true} onChange={(action) => removeDuration(action.target)} name={activity_duration.activity_duration} sx={{marginLeft: "10px"}} />
                                        }
                                        label={`${activity_duration.activity_duration} minutes`}
                                    />
                                )
                            })}
                        </FormGroup>
                    </div>
                    <div style={{display: 'flex', padding: "10px", justifyContent: "center", alignItems: 'center', paddingBottom: "20px"}}>
                        <TextField id="outlined-basic" label="New Duration in Minutes" defaultValue={0} variant="outlined" onChange={(action) => setAddedDuration(parseInt(action.target.value))} sx={{marginLeft: '200px'}} />
                        <Button variant="contained" onClick={() => addDuration(addedDuration)} sx={{marginLeft: "10px", minWidth: "30px", width: "30px"}}>+</Button>
                    </div>
                    <div style={{display: 'flex', padding: "10px", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                        Career Fair Visible to Users: <Checkbox checked={visible} onChange={(action) => setVisible(!visible)} name={'visible'} sx={{marginLeft: "25px"}} />
                    </div>
                </div>
                <Alert severity={"error"} onClose={() => {setShowAddAlert(false)}} sx={{marginBottom: "20px", visibility: showAddAlert ? 'visible' : 'hidden'}}>
                    {alertText}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{color: "black", backgroundColor: "#ECECEC", marginTop: 'auto', marginBottom: '10px'}}
                    onClick={()=>cancelButtonClick()}
                >
                    Cancel
                </Button>
                <Button
                    sx={{color: "black", backgroundColor: "#5DFF4F", marginTop: 'auto', marginBottom: '10px'}}
                    onClick={()=>saveButtonClick()}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditCareerFairModal;