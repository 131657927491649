/*
    Route endpoints to the correct pages

    Author: Brendan Young
 */

import {Routes, Route, Navigate} from 'react-router-dom';
import App from '../App';
import NotFoundPage from "./NotFoundPage";
import {useUser} from "../Services/UserService/userContext"
import UnauthorizedPage from "./UnauthorizedPage";
import SchedulePage from './SchedulePage';
import LoginPage from './LoginPage';
import CareerFairListingPage from './CareerFairListingPage';
import AdminDashboardPage from './AdminDashboardPage';
import LocationsPage from './LocationsPage';
import LocationAssignPage from './LocationAssignPage';

    
    const pages = [
        {
            "name": "Home",
            "path": "/",
            "element": <App/>,
            "authLevel": {
                "default": true,
                "STUDENT": true,
                "EMPLOYER": true,
                "ADMIN": true
            }
        },
        {
            "name": "Home",
            "path": "/403",
            "element": <UnauthorizedPage/>,
            "authLevel": {
                "default": true,
                "STUDENT": true,
                "EMPLOYER": true,
                "ADMIN": true
            }
        },
        {
            "name": "Schedule",
            "path": "/schedule",
            "element": <SchedulePage/>,
            "authLevel": {
                "default": true,
                "STUDENT": true,
                "EMPLOYER": true,
                "ADMIN": true
            }
        },
    
        {
            "name": "Login",
            "path": "/login",
            "element": <LoginPage/>,
            "authLevel": {
                "default": true,
                "STUDENT": true,
                "EMPLOYER": true,
                "ADMIN": true
            }
        },
        {
            "name": "CareerFairListing",
            "path": "/CareerFairs",
            "element": <CareerFairListingPage/>,
            "authLevel": {
                "default": false,
                "STUDENT": true,
                "EMPLOYER": true,
                "ADMIN": true
            }
        },
        {
            "name": "AdminDashboard",
            "path": "/AdminDashboard",
            "element": <AdminDashboardPage/>,
            "authLevel": {
                "default": false,
                "STUDENT": false,
                "EMPLOYER": false,
                "ADMIN": true
            }
        },
        {
            "name": "Locations",
            "path": "/locations",
            "element": <LocationsPage/>,
            "authLevel": {
                "default": false,
                "STUDENT": false,
                "EMPLOYER": false,
                "ADMIN": true
            }
        },
        {
            "name": "LocationAssign",
            "path": "/locationAssign",
            "element": <LocationAssignPage/>,
            "authLevel": {
                "default": false,
                "STUDENT": false,
                "EMPLOYER": false,
                "ADMIN": true
            }
        }
    ]
    
    export default function PageRouter(p) {
        const {isAuth, user} = useUser()

        return (
            <>
                <Routes>
                    {pages.map(page =>
                        <Route
                            key={page.name}
                            path={page.path}
                            element={
                                 isAuth(page.authLevel) ? page.element : <Navigate to="/403" />
                            }
                        />
                        )}
                    <Route key="default" path="/*" element={<NotFoundPage/>} />
                </Routes>
            </>
    
        )
    }