import React from "react";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";

export function ScheduleBackground() {
    return (
        <Table stickyHeader className="background-table">
            <TableBody>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
                <TableRow className="background-row">
                    <TableCell></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export function HourMarkers() {

    const hours = [
        "7 AM",
        "8 AM",
        "9 AM",
        "10 AM",
        "11 AM",
        "12 AM",
        "1 PM",
        "2 PM",
        "3 PM",
        "4 PM",
        "5 PM",
    ]

    return (
        <div className="hour-markers-container">
            {hours.map((hour, index) => { return(
                <div className={`hour-marker ${index === 0 ? 'first' : ''}`}>
                    {hour}
                </div>
            )})}
        </div>
    )
}